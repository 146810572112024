import React, {useEffect, useState} from "react";
import {Backdrop, Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {store} from "../../../store/store";
import {setActiveTutorial, setActiveView} from "../../../store/slices/global";
import ScanMafiNumberComponent from "../../../components/mafi/scanMafiNumber.component";
import {ROUTES} from "../../../routes/routes";
import {generatePath} from "react-router";
import LoadingImage from "../../../assets/images/loading-animation.svg";
import {defaultButtonStyle, secondaryButtonProps, secondaryButtonStyle} from "../../../styles/defaultStyles";

const MafiInfoView: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    /**
     * the current mafiNr
     */
    const [mafiNr, setMafiNr] = useState<string>('');
    const [isScanning, setScanning] = useState<boolean>(false);

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveTutorial({tutorial: "FUNCTION", show: false}));
        store.dispatch(setActiveView({text: t('TASK_SELECTION.MAFI_INFO'), params: null}));
    }, []);

    function openMafiDetailsView(mafiNo: string) {
        const routingPath = generatePath(ROUTES.MAFI_INFO_DETAILS, {mafiNr: mafiNo});
        navigate(routingPath);
    }

    function onCancelPressed() {
        navigate(ROUTES.HOME);
    }

    const onStartScan = () => {
        setScanning(true);
    }

    const onFinishScan = () => {
        setScanning(false);
    }

    return (
        <Box sx={{
            height: "100%", display: "flex", alignItems: "center", flexDirection: "column",
            backgroundColor: theme.palette.surface.main,
        }}>
            <Box sx={{p: 3, display: "flex", flexDirection: "column", width: "100%"}}>
                <ScanMafiNumberComponent mafiNr={mafiNr} selectTagEvent={(tag, isInput) => {
                    setMafiNr(tag.parsedId);
                    if (!isInput) {
                        openMafiDetailsView(tag.parsedId);
                    }
                }}
                                         onStartScan={onStartScan}
                                         onFinishScan={onFinishScan}
                />
                <Button
                    sx={{
                        ...defaultButtonStyle,
                        marginTop: "20px",
                        width: "100%",
                        height: 74,
                        borderRadius: "6px",
                        textTransform: 'none',
                        fontSize: 18,
                        fontWeight: 500
                    }}
                    disabled={!mafiNr || mafiNr.length <= 0}
                    variant="contained"
                    color="primary"
                    size="large"
                    data-testid="write-mafi-details-submit"
                    onClick={() => {
                        openMafiDetailsView(mafiNr);
                    }}
                >{t("MAFI_INFORMATION.GET_INFO")}</Button>
                <Button
                    {...secondaryButtonProps}
                    sx={{...secondaryButtonStyle, mt: 2}}
                    onClick={onCancelPressed}>
                    {t('GENERAL.CANCEL')}
                </Button>
                <Typography sx={{fontSize: 14, mt: 2}}>
                    Hier kannst du dir die Auskunft für einen Mafi ausgeben lassen. Scanne dazu das Tag eines Mafis ein
                    und drücke anschließend auf die Schaltfläche.
                </Typography>
                <Backdrop
                    sx={{
                        backgroundColor: "rgba(222,224,233,0.9)",
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    open={isScanning}>
                    <object type="image/svg+xml" style={{width: 275, height: 275}} data={LoadingImage}>svg-animation
                    </object>
                    <Typography>Tags in der Nähe werden gesucht ...</Typography>
                </Backdrop>
            </Box>
        </Box>
    );
};

export default MafiInfoView;
