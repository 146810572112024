import {MafiInformation} from "@blg/blg-core";
import React from "react";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {mafiTypes} from "../../misc/mafiTypes";
import {defaultStyles} from "../../styles/defaultStyles";
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {store} from "../../store/store";
import {setMafiInfoExpanded} from "../../store/slices/custom";
import {useAppSelector} from "../../store/hooks";


interface InformationInterface {
	name: string,
	value: string,
	display: boolean
}

interface MafiInformationComponentProps {
	information: MafiInformation,
	showDetailed?: boolean
}

const MafiInformationComponent = (props: MafiInformationComponentProps) => {
	const {t} = useTranslation();
    const isExpanded = useAppSelector(state => state.custom).mafiInfoExpanded;

	const getTypeByCode = () => {
		return mafiTypes.find((t) => t.code === props.information.typeCode);
	}

	function showDetailed(): boolean {
		return props.showDetailed ?? true;
	}

	const information: InformationInterface[] = [
		{name: t('MAFI_INFORMATION.TYPE'), value: t(getTypeByCode()?.title ?? ''), display: showDetailed()},
		{name: t('MAFI_INFORMATION.VESSEL'), value: props.information.entryVesselName ?? '-', display: showDetailed()},
		{name: t('MAFI_INFORMATION.SHIP_OWNER'), value: props.information.mafiOwnerCode ?? '-', display: true},
		{name: t('MAFI_INFORMATION.LASHING_METHOD'), value: t(`LASHING_METHOD.${props.information.lashingMethod?.toString()}`),
			display: props.information.lashingMethod !== undefined},
		{name: t('MAFI_INFORMATION.LENGTH'), value: props.information.length?.toString(), display: true},
		{name: t('MAFI_INFORMATION.WIDTH'), value: props.information.width?.toString(), display: showDetailed()},
		{name: t('MAFI_INFORMATION.PAYLOAD'), value: props.information.weightMaxLoad?.toString(), display: true},
		{name: t('MAFI_INFORMATION.TARA'), value: props.information.tara?.toString(), display: true},
	];

	return (
        <Accordion expanded={isExpanded} onChange={(event, expanded) => {
            store.dispatch(setMafiInfoExpanded(expanded));
        }} style={defaultStyles.contentBox} sx={{ width: "100%" }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography sx={{fontSize: '18px', fontWeight: '600', color: '#000', width: "100%", textAlign: "center"}}>
                    {props.information.mafiNo} { `${props.information.stackNo ?  `(im Stack ${props.information.stackNo})` : "" }` }
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {information.filter((i) => i.display).map((i) => <Box
                    key={i.name}
                    sx={{py: 1.5, borderTop: '1px solid #D9DDE3', width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography sx={{color: '#000'}}>{i.name}</Typography>
                    <Typography sx={{fontWeight: 600, color: '#000'}}>{i.value}</Typography>
                </Box>)}
            </AccordionDetails>
        </Accordion>
	);
};

export default MafiInformationComponent;
