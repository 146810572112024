import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface WetDialogProps {
    showDialog: boolean;
    closeDialog: (confirmed: boolean) => void;
}

const WetDialogComponent = (props: WetDialogProps) => {
    const {t} = useTranslation();

    return (
        <Dialog
            maxWidth="xs"
            open={props.showDialog}
            onClose={() => props.closeDialog(false)}
        >
            <DialogTitle>{t("WET_DIALOG.TITLE")}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t("WET_DIALOG.TEXT")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.closeDialog(false)} color="primary">
                    {t("GENERAL.CANCEL")}
                </Button>
                <Button onClick={async () => {
                    props.closeDialog(true);
                }} color="primary">
                    {t("GENERAL.CONFIRM")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default WetDialogComponent;
