import {Parseable} from "@blg/blg-core";

export default class Inventory extends Parseable {
	id!: string;
	inventoryName!: string;
	active!: boolean;

	public static parseFromObject(object: any): Inventory {
		const inventory = new Inventory();
		Object.assign(inventory, object);

		return inventory;
	}
}