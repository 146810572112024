import React, {useEffect} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {store} from "../store/store";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../routes/routes";
import {useTheme} from "@mui/material/styles";
import {setActiveTutorial, setActiveView, setShowBackButton} from "../store/slices/global";
import {ArrowForward} from "@material-ui/icons";

import highlight_background from '../assets/images/highlight_background.png';
import {makeStyles} from "@mui/styles";
import {lightShadow} from "../styles/defaultStyles";
import {resetState, setIsStack} from "../store/slices/union";

const backgroundImageStyle = makeStyles({
    buttonStep: {
        backgroundImage: `url(${highlight_background})`,
        backgroundSize: 'cover'
    }
});

/**
 * defines a task type
 */
interface TaskType {
    title: string,
    route?: ROUTES,
    action?: () => void,
}

const TaskSelectionView: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const buttonImageStyle = backgroundImageStyle();
    // const token = useAppSelector(state => state.auth).token;
    // const refreshToken = useAppSelector(state => state.auth).refreshToken;

    /**
     * is called when the user clicks on stack task selection button,
     * opens stack app (in browser or as android intent)
     */
    /*
    const onStackPressed = () => {
        if (isScanner()) {
            const url = `rotrailstack://app?token=${token}&refreshToken=${refreshToken}&launcher=true`;
            window.open(url, "_system");
            return;
        }

        SnackbarUtil.info(t('STACK.INFO'));
        window.open(`${process.env.REACT_APP_STACK_APP_URL}?refreshToken=${refreshToken}`, "_blank")?.focus();
    }
    */
    /*
    const isScanner = () => {
        return window.cordova !== undefined;
    }
    */
    /**
     * the different task types, an item is drawn for each task inside this view to select the desired task
     */
    const taskTypes: Array<TaskType> = [
        {title: "TASK_SELECTION.INVENTORY", route: ROUTES.INVENTORY_SCAN},
        {title: "TASK_SELECTION.STACK", route: ROUTES.STACK_TASK},
        {title: "TASK_SELECTION.SET_PLACE", route: ROUTES.SET_PLACE},
        {title: "TASK_SELECTION.MAFI_INFO", route: ROUTES.MAFI_INFO},
    ];

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveView({text: '', params: null}));
        store.dispatch(setShowBackButton(true));

        store.dispatch(resetState());

        store.dispatch(setActiveTutorial({tutorial: "FUNCTION", show: true}));
    }, []);


    /**
     * is called when the user has selected a task, opens the
     * specific screen
     * @param task
     * @param event
     */
    function onTaskSelected(task: TaskType, event: React.MouseEvent<HTMLButtonElement>) {
        if (task.route) {
            navigate(task.route!);
            return;
        }

        (event.target as HTMLButtonElement).classList.add('noHover');
        task.action?.call(null);
    }

    function onUnionSelected() {
        /**
         * Reset the stack state before opening the union view
         */
        store.dispatch(setIsStack(false));
        navigate(ROUTES.UNION);
    }

    /*
    function connectToRFID() {
        if (window.cordova) {
            // writeRFID("0043094E0045005536373513", "CRTU4117675", writeSuccessCallback, writeErrorCallback);
        }
    }

    function writeSuccessCallback(message: string) {
        console.log('writeSuccessCallback', message)
    }

    function writeErrorCallback(message: string) {
        console.log('writeErrorCallback', message)
    }
    */
    return (<Box sx={{height: "100%", display: "flex", flexDirection: "column"}}>
        <Box sx={{
            display: 'flex',
            px: 3,
            pt: 3.5,
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.surface.main
        }}>
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Button
                    className={buttonImageStyle.buttonStep}
                    onClick={onUnionSelected}
                    sx={{
                        height: '126px', borderRadius: "6px", textTransform: 'none', fontSize: '18px', display: 'flex',
                        justifyContent: 'space-between', alignItems: 'flex-end', pl: 3, pb: 3
                    }} variant={"contained"}>
                    <Typography sx={{color: theme.palette.white.main, fontWeight: 600}}> {t('TASK_SELECTION.UNION')}</Typography>
                    <Box sx={{
                        width: 30, height: 30, borderRadius: 30, backgroundColor: theme.palette.surface.main,
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <ArrowForward color={"primary"}/>
                    </Box>
                </Button>

                {taskTypes.map((task, index) =>
                    <Button
                        className={""}
                        sx={{
                            height: '56px',
                            mt: 2,
                            textTransform: 'none',
                            borderRadius: "6px",
                            pl: 3,
                            backgroundColor: theme.palette.white.main,
                            color: theme.palette.white.main,
                            display: 'flex',
                            justifyContent: 'space-between', ...lightShadow,
                            "&:hover": {backgroundColor: theme.palette.primary.main},
                            "&:hover p": {color: theme.palette.grey.A100},
                            "&.MuiButtonBase-root.noHover:hover": {
                                backgroundColor: theme.palette.white.main,
                                boxShadow: "none",
                                ".MuiTypography-root": {
                                    color: `${theme.palette.white.main} !important`,
                                }
                            },
                        }}
                        variant={"contained"}
                        onClick={(event: any) => {
                            onTaskSelected(task, event);
                        }}
                        onMouseEnter={(event: any) => {
                            event.target.classList.remove('noHover');
                        }}
                        key={index}>
                        <Typography sx={{fontWeight: 600, fontSize: '16px'}}>
                            {t(task.title)}
                        </Typography>
                        <Box sx={{
                            width: 30, height: 30, borderRadius: 30, backgroundColor: theme.palette.surface.main,
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>
                            <ArrowForward color={"primary"}/>
                        </Box>
                    </Button>)}
                <Box id="hidden-box"></Box>
            </Box>
        </Box>
    </Box>);
};

export default TaskSelectionView;
