import React from "react";
import {Box} from "@mui/material";
import { StackDetailComponent } from '@blg/blg-core'
import {useAppSelector} from "../../../store/hooks";
import {setLastSavedLocation} from "../../../store/slices/custom";
import {setActiveView, setShowLoading} from "../../../store/slices/global";
import {store} from "../../../store/store";

const StackDetailView = () => {
    const lastSavedLocation = useAppSelector(state => state.custom).lastSavedLocation;
    const mdeLatitude = useAppSelector(state => state.custom).latitude;
    const mdeLongitude = useAppSelector(state => state.custom).longitude;

    return <Box sx={{ height: "100%" }}>
        <StackDetailComponent
            latitude={mdeLatitude}
            longitude={mdeLongitude}
            lastSavedLocation={lastSavedLocation}
            updateLastSavedLocation={(location) => store.dispatch(setLastSavedLocation(location!.notation))}
            setShowLoading={(loading) => store.dispatch(setShowLoading(loading))}
            setActiveView={(activeView) => store.dispatch(setActiveView(activeView))} />
    </Box>
}

export default StackDetailView;
