import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface AuthState {
    token?: string,
    refreshToken?: string,
    username?: string
}

const initialState: AuthState = {token: undefined, refreshToken: undefined, username: undefined};

const authState = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken(state: AuthState, action: PayloadAction<string>) {
            state.token = action.payload
        },
        setRefreshToken(state: AuthState, action: PayloadAction<string>) {
            state.refreshToken = action.payload;
        },
        setUsername(state: AuthState, action: PayloadAction<string>) {
            state.username = action.payload
        },
    },
});

export const {setToken, setUsername, setRefreshToken} = authState.actions;
export default authState.reducer;
