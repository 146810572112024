import {MafiInformation, MafiNotation} from "@blg/blg-core";
import localForage from 'localforage';
import MafiCategory from "../models/MafiCategory";
import MafiOwner from "../models/api/MafiOwner";

enum LocalForageKeys {
    MAFIS = "MAFIS", CATEGORIES = "CATEGORIES", NOTATION = "NOTATIONS", PARSED_NOTATIONS = "PARSED_NOTATIONS", OWNERS = "OWNERS",
}

export default class LocalForageHelper {
    private static _instance?: LocalForageHelper; // Used for the singleton pattern

    constructor() {
        if (LocalForageHelper._instance) {
            return LocalForageHelper._instance;
        }

        LocalForageHelper._instance = this;
    }

    // Create the singleton
    static get instance() {
        return LocalForageHelper._instance ?? (LocalForageHelper._instance = new LocalForageHelper());
    }

    async setAllMafis(mafis: MafiInformation[]) {
        return localForage.setItem(LocalForageKeys.MAFIS, mafis);
    }

    async setAllMafiNotations(notations: MafiNotation[]) {
        return localForage.setItem(LocalForageKeys.NOTATION, notations);
    }

    async setParsedNotations(notations: Map<string, MafiNotation> ) {
        return localForage.setItem(LocalForageKeys.PARSED_NOTATIONS, notations);
    }

    async setSearchSettings(categories: MafiCategory[]) {
        await Promise.all([
            localForage.setItem(LocalForageKeys.CATEGORIES, categories)
        ]);
    }

    async setMafiOwners(owners: MafiOwner[]) {
        await localForage.setItem(LocalForageKeys.OWNERS, owners);
    }

    async loadAllMafis(): Promise<MafiInformation[]> {
        const mafis = await localForage.getItem(LocalForageKeys.MAFIS) as Partial<MafiInformation>[];
        return MafiInformation.parseFromArray(mafis) as MafiInformation[];
    }

    async getAllNotations(): Promise<MafiNotation[]> {
        const notations = await localForage.getItem(LocalForageKeys.NOTATION) as Partial<MafiNotation[]>;
        return MafiNotation.parseFromArray(notations) as MafiNotation[];
    }

    async getParsedNotations(): Promise<Map<string, MafiNotation> > {
        return await localForage.getItem(LocalForageKeys.PARSED_NOTATIONS) as Map<string, MafiNotation>;
    }

    async loadMafiByMafno(mafiNo: string) {
        const allMafis = await this.loadAllMafis();
        return allMafis.find(mafi => mafi.mafiNo === mafiNo);
    }

    async loadCategories(): Promise<MafiCategory[]> {
        const forageData = await localForage.getItem(LocalForageKeys.CATEGORIES) as Partial<MafiCategory[]>;
        return MafiCategory.parseFromArray(forageData) as MafiCategory[];
    }

    async loadOwners(): Promise<MafiOwner[]> {
        const forageData = await localForage.getItem(LocalForageKeys.OWNERS) as Partial<MafiOwner[]>;
        return MafiOwner.parseFromArray(forageData) as MafiOwner[];
    }
}
