import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Zoom from 'react-medium-image-zoom'
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Box, Button, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

import FunctionTutorial from "../../assets/images/tutorial/FUNCTIONS.jpg";
import LocationTutorial from "../../assets/images/tutorial/LOCATION.jpg";
import LocationDetailsTutorial from "../../assets/images/tutorial/LOCATION_DETAILS.jpg";
import UnionTutorial from "../../assets/images/tutorial/UNION.jpg";
import UnionMinimalTutorial from "../../assets/images/tutorial/UNION_MINIMAL.jpg";
import UnionSaveTutorial from "../../assets/images/tutorial/UNION_SAVE.jpg";
import {useAppSelector} from "../../store/hooks";
import {Tutorial} from "../../store/slices/global";

const defaultStyle = {
    tutorialImg: {
        width: '100%'
    }
}

export interface TutorialDialogProps {
    showDialog: boolean,

    closeEvent(): any,
}

const TutorialDialogComponent = (props: TutorialDialogProps) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    const activeTutorial = useAppSelector(state => state.global).activeTutorial;

    const [navTutorial, setNavTutorial] = useState<{ tutorial: Tutorial, show: boolean }>(
        {tutorial: "FUNCTION", show: true});

    useEffect(() => {
        setNavTutorial(activeTutorial);
    }, [activeTutorial]);

    useEffect(() => {
        setOpen(props.showDialog);
    }, [props.showDialog])

    const getImg = () => {
        switch (navTutorial.tutorial) {
            case "FUNCTION":
                return <img style={defaultStyle.tutorialImg} src={FunctionTutorial}/>;
            case "LOCATION":
                return <img style={defaultStyle.tutorialImg} src={LocationTutorial}/>;
            case "LOCATION_DETAILS":
                return <img style={defaultStyle.tutorialImg} src={LocationDetailsTutorial}/>;
            case "UNION":
                return <img style={defaultStyle.tutorialImg} src={UnionTutorial}/>;
            case "UNION_MINIMAL":
                return <img style={defaultStyle.tutorialImg} src={UnionMinimalTutorial}/>;
            case "UNION_SAVE":
                return <img style={defaultStyle.tutorialImg} src={UnionSaveTutorial}/>;
        }
    };

    return (
        <Box>
            <Dialog
                onClose={() => props.closeEvent()}
                maxWidth={"xl"}
                open={open}
            >
                <DialogTitle sx={{textAlign: "center"}}>
                    Tutorial
                </DialogTitle>
                <DialogContent sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Zoom>
                        {getImg()}
                    </Zoom>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        startIcon={<CloseIcon/>}
                        onClick={props.closeEvent}>
                        {t("GENERAL.INFO_DIALOG.CLOSE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TutorialDialogComponent;
