import React, {useState} from "react";
import {
	Box,
	Button,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {MafiType} from "../../models/MafiType";
import {defaultType, mafiTypes} from "../../misc/mafiTypes";
import {defaultButtonProps, defaultButtonStyle} from "../../styles/defaultStyles";
import {Check, Close} from "@material-ui/icons";

interface UnionTypeDialogProps {
	showDialog: boolean,
	onTypeSelected?: (type: MafiType | null) => void,
	onClose?: () => void,
	initialType?: MafiType | null,
}

const UnionTypeDialog = (props: UnionTypeDialogProps) => {
	const {t} = useTranslation();
	const theme = useTheme();

	const [selectedType, setSelectedType] = useState<MafiType | null>(props.initialType ?? defaultType() ?? null);

	const onSelected = () => {
		props.onTypeSelected?.call(null, selectedType);
	}

	const onClose = () => {
		props.onClose?.call(null);
	}

	const isSelected = (type: MafiType): boolean => {
		return type.code === selectedType?.code;
	}

	return (
		<Drawer
			style={{margin: 0}}
			sx={{backgroundColor: 'transparent', borderRadius: '24px 24px 0px 0px'}}
			open={props.showDialog}
			onClose={onClose}
			anchor={"bottom"}
			PaperProps={{ elevation: 0, style: {backgroundColor: 'transparent'}}}
		>
			<Box sx={{backgroundColor: theme.palette.background.default, px: 5, pb: 5, pt: 3, borderRadius: '24px 24px 0px 0px'}}>
				<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
					<Typography>Typ auswählen</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</Box>
				<List sx={{width: '100%'}}>
					{mafiTypes.map((i) => <ListItem
						secondaryAction={(isSelected(i) ? <Check color="primary" />: null)}
						onClick={() => setSelectedType(i)}
						key={i.code}
						sx={{
							border: isSelected(i) ? '1px solid rgb(54, 105, 168)': null,
							backgroundColor: theme.palette.white.main, mt: 2.5, width: '100%', boxShadow: '0px 5px 10px rgba(54, 105, 168, 0.25)',
							borderRadius: 2}}>
						<ListItemText primary={t(i.title)} secondary={i.description}></ListItemText>
					</ListItem>)}
				</List>

				<Button
					onClick={onSelected}
					disabled={!selectedType}
					{...defaultButtonProps}
					sx={{...defaultButtonStyle, mt: 5}}
				>
					{t('GENERAL.NEXT')}
				</Button>
			</Box>
		</Drawer>
	);
};

export default UnionTypeDialog;
