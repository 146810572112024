import {MafiType} from "../models/MafiType";

export const mafiTypes: MafiType[] = [{
	title: 'MAFI_TYPES.MAFI',
	description: 'Lorem ipsum',
	code: 'ROLLTRAILER'
}, {
	title: 'MAFI_TYPES.CONTAINER',
	description: 'Lorem ipsum',
	code: 'CONTAINER'
}, {
	title: 'MAFI_TYPES.BOLSTER',
	description: 'Lorem ipsum',
	code: 'BOLSTER'
}
];

export const defaultType = (): MafiType | undefined => {
	return mafiTypes.find((t) => t.code === 'ROLLTRAILER');
}
