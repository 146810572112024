import { useEffect } from 'react';
import useState from 'react-usestateref'

const useDetectKeyboardOpen = (minKeyboardHeight = 200) => {
    const [isKeyboardOpen, setIsKeyboardOpen, isKeyboardOpenRef] = useState(false);

    useEffect(() => {
        const listener = () => {
            const newState = window.screen.height - minKeyboardHeight > window.visualViewport!.height;
            if (isKeyboardOpenRef.current != newState) {
                setIsKeyboardOpen(newState);
            }
        };
        if (window.cordova) {
            if (typeof visualViewport != 'undefined') {
                window.visualViewport!.addEventListener('resize', listener);
            }
        }
        return () => {
            if (typeof visualViewport != 'undefined') {
                window.visualViewport!.removeEventListener('resize', listener);
            }
        };
    }, []);

    return isKeyboardOpen;
};

export default useDetectKeyboardOpen;
