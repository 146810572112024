import * as React from 'react';
import {Box, FormControl, InputAdornment, TextField, Icon} from "@mui/material";
import Barcode from "../../assets/images/Barcode.svg";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {lightShadow} from "../../styles/defaultStyles";
import {Emitter} from "@blg/blg-core";
import RfidTagSelectionDialogComponent, {RFIDScanResult} from "../dialog/rfidTagSelectionDialogComponent";
import {asciiToHex} from "../../misc/rfidHelper";
import {SnackbarUtil} from "@blg/blg-core/lib/esm/components";

const maxInputLength = 12;

export interface ScanMafiNumberProps {
    disabled?: boolean,
    mafiNr: string,
    onStartScan?: () => void,
    onFinishScan?: (result: string) => void,
    validationState?: boolean
    writeMode?: boolean,

    selectTagEvent(tag: RFIDScanResult, isInput: boolean): void,
}

const ScanMafiNumberComponent = (props: ScanMafiNumberProps) => {
    const {t} = useTranslation();

    const [tagSelection, setTagSelection] = useState<{ show: boolean, tags: RFIDScanResult[] }>({
        show: false,
        tags: []
    });
    const [mafiNr, setMafiNr] = useState<string>(props.mafiNr);

    /**
     * checks if the application is running on scanner or in browser
     */
    const isScanner = () => {
        return window.cordova !== undefined;
    }

    /**
     * if the application is running on scanner try to connect to the scanner on start
     */
    useEffect(() => {
        if (!isScanner() || props.writeMode) return;
        Emitter.on("TAGS", foundTags);
        return () => {
            Emitter.removeListener("TAGS");
        }
    }, []);

    const foundTags = (tags: Array<RFIDScanResult>) => {
        if ((!tags || tags.length === 0) && !tagSelection.show) {
            SnackbarUtil.warning("Keine Tags gefunden");
            return;
        }

        // Hide all messages
        SnackbarUtil.closeAll();
        if (tags.length === 1) {
            // If only one tag is found it can be automatically be selected
            props.selectTagEvent(tags[0], false);
            setMafiNr(tags[0].parsedId);
            return;
        }

        setTagSelection({show: true, tags: tags});
    };

    function selectedMafi(tag: RFIDScanResult): void {
        setTagSelection({show: false, tags: []});
        setMafiNr(tag.parsedId);
        props.selectTagEvent(tag, false);
    }

    const isDisabled = () => {
        if (props.disabled) return true;
    }

    const getErrorMessage = () => {
        if (props.writeMode && mafiNr.length <= 5 && props.validationState) {
            return "Bitte mindestens 6 Zeichen eintragen";
        }

        return "";
    }

    return (
        <Box sx={{width: "100%"}}>
            <FormControl variant={"filled"} sx={{width: '100%'}}>
                <TextField
                    sx={{...lightShadow}}
                    value={mafiNr}
                    helperText={getErrorMessage()}
                    onChange={(v) => {
                        if (v.target.value.length <= maxInputLength) {
                            const mafiNr = v.target.value.toUpperCase();
                            setMafiNr(mafiNr);
                            props.selectTagEvent({id: asciiToHex(mafiNr), rssi: 0, parsedId: mafiNr, pcValue: "0"}, true);
                        }
                    }}
                    disabled={isDisabled()}
                    label={props.writeMode ? t('SCAN_MAFI.WRITE_MAFI') : t('SCAN_MAFI.SCAN_MAFI')}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <Icon>
                                <img src={Barcode} height={25} width={25} alt={"barcode"}/>
                            </Icon>
                        </InputAdornment>)
                    }}
                />
            </FormControl>
            <RfidTagSelectionDialogComponent
                showDialog={tagSelection.show}
                selectEvent={(info) => selectedMafi(info)}
                closeEvent={() => {
                    setTagSelection({show: false, tags: []});
                }}
                tags={tagSelection.tags}/>
        </Box>
    )
}

export default ScanMafiNumberComponent;
