import React from "react";
import {Box, Button} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../routes/routes";
import {useAppSelector} from "../../../store/hooks";
import {setShowBackButton} from "../../../store/slices/global";
import {store} from "../../../store/store";
import {
    defaultButtonProps,
    defaultButtonStyle,
    secondaryButtonProps,
    secondaryButtonStyle
} from "../../../styles/defaultStyles";
import {resetState, setMafis} from "store/slices/union";
import {useTranslation} from "react-i18next";
import {saveMafiStack} from "misc/mafiHelper";
import { MafiStackOrderComponent } from "@blg/blg-core";

const UnionOrderStackView: React.FC = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const mafis = useAppSelector(state => state.union).mafis;

    /**
     * is called when the user clicks on the save button,
     * tries to create / update the scanned mafi (based on if the mafi is new or
     * not)
     */
    const onSavePressed = async () => {
        const result = await saveMafiStack(mafis);
        if (result) {
            store.dispatch(setShowBackButton(false));
            // updating / creating was successful, therefore reset the union state
            // and navigate back to the union view
            store.dispatch(resetState());
            navigate(ROUTES.UNION);
        }
    }

    return (
        <Box sx={{
            width: "100%", height: "100%", display: "flex", alignItems: "center",
            flexDirection: "column", backgroundColor: theme.palette.surface.main
        }}>
            <MafiStackOrderComponent
                slim
                items={mafis}
                onItemsChanged={(newState) => {
                store.dispatch(setMafis(newState));
            }}/>
            <Box sx={{ px: 3, width: "100%", display: "flex", flexDirection: "column" }}>
                <Button
                    {...defaultButtonProps}
                    disabled={mafis.length === 0}
                    sx={{...defaultButtonStyle, mt: 5}}
                    onClick={onSavePressed}
                >{t("GENERAL.SAVE")}</Button>
                <Button
                    {...secondaryButtonProps}
                    sx={{...secondaryButtonStyle, mt: 4, mb: 7.5}}
                    onClick={() => {
                        navigate(ROUTES.TASK_SELECTION);
                    }}>
                    {t('GENERAL.CANCEL')}
                </Button>
            </Box>
        </Box>
    );
};

export default UnionOrderStackView;
