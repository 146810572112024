import React from "react";
import {Box} from "@mui/material";
import { UnstackDetailComponent } from '@blg/blg-core'
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../routes/routes";
import {setShowLoading} from "../../../store/slices/global";
import {store} from "../../../store/store";

enum UnstackRoute {
    HOME, TASK_SELECTION
}

const UnStackDetailView = () => {
    const navigate = useNavigate();

    return <Box sx={{ height: "100%" }}>
        <UnstackDetailComponent
            navigateToRoute={(route: UnstackRoute) => {
                if (route === UnstackRoute.TASK_SELECTION) {
                    navigate(ROUTES.TASK_SELECTION);
                } else {
                    navigate(ROUTES.HOME);
                }
            }}
            setShowLoading={(loading) => store.dispatch(setShowLoading(loading))}/>
    </Box>
}

export default UnStackDetailView;
