import {
    MafiInformation,
    RoTrailMafiService,
    RoTrailInventoryService,
    MafiLocationCoordinates,
    MafiNotation
} from "@blg/blg-core";
import React, {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import ScanMafiNumberComponent from "../../../components/mafi/scanMafiNumber.component";
import {useAppSelector} from "../../../store/hooks";
import {setLastSavedLocation} from "../../../store/slices/custom";
import {store} from "../../../store/store";
import {setActiveTutorial, setActiveView, setShowLoading} from "../../../store/slices/global";
import {SnackbarUtil, LiveMafiLocationComponent} from "@blg/blg-core";
import {
    defaultButtonProps,
    defaultButtonStyle,
    secondaryButtonProps,
    secondaryButtonStyle
} from "../../../styles/defaultStyles";
import EmptyMafiPlaceholder from "../../../components/mafi/emptyMafiPlaceholder.component";
import {MafiLocationDto} from "../../../models/api/dto/MafiLocationDto";
import Inventory from "../../../models/api/Inventory";
import {RFIDScanResult} from "../../../components/dialog/rfidTagSelectionDialogComponent";

const InventoryScanView: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    const lastSavedLocation = useAppSelector(state => state.custom).lastSavedLocation;

    const mdeLatitude = useAppSelector(state => state.custom).latitude;
    const mdeLongitude = useAppSelector(state => state.custom).longitude;

    /**
     * the current mafiNr
     */
    const [mafiNr, setMafiNr] = useState<string>('');
    const [inventory, setInventory] = useState<string>('');
    const [activeMafi, setActiveMafi] = useState<MafiInformation | null>(null);
    const [isEmpty, setEmpty] = useState<boolean>(false);
    const [location, setLocation] = useState<MafiLocationCoordinates | null>(null);
    const [notation, setNotation] = useState<string>('');

    const [locationValid, setLocationValid] = useState(false);

    useEffect(() => {
        store.dispatch(setActiveTutorial({tutorial: "FUNCTION", show: false}));
        loadInventory();
    }, []);

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveView({text: t('TASK_SELECTION.INVENTORY'), params: null}));
    }, []);

    const loadInventory = async () => {
        store.dispatch(setShowLoading(true));
        try {
            const response = await RoTrailInventoryService.instance.getInventories();
            const data = Inventory.parseFromArray(response.data) as Inventory[];

            // for now the first inventory is always used
            if (data.length > 0) {
                setInventory(data[0].id);
            }
        } finally {
            store.dispatch(setShowLoading(false));
        }
    }


    /**
     * is called when the user goes back to the inventory overview
     */
    function onCancelPressed() {
        navigate(-1);
    }

    function onCancelSetPlace() {
        setEmpty(false);
        setActiveMafi(null);
    }

    function notationChanged(isValid: boolean, notation?: MafiNotation) {
        if (isValid) {
            setNotation(notation!.notation);
            store.dispatch(setLastSavedLocation(notation!.notation));
        }
        setLocationValid(isValid);
    }

    const loadMafiData = async () => {
        store.dispatch(setShowLoading(true));
        try {
            // searches for the mafi based on the number and gets the details of the mafi based on that
            const response = await RoTrailMafiService.instance.getByMafiNo(mafiNr);
            if (response.data) {
                const mafi = MafiInformation.parseFromObject(response.data);
                setActiveMafi(mafi);
            } else {
                setActiveMafi(null);
                SnackbarUtil.error(t("INVENTORY.NOT_FOUND", {mafiNo: mafiNr}));
                // setEmpty(true);
            }
        } catch (e) {
            SnackbarUtil.error(t('MAFI_INFORMATION.ERROR', {'value': mafiNr}));
        } finally {
            store.dispatch(setShowLoading(false));
        }
    }

    const openSetPlace = async () => {
        void loadMafiData();
    }

    /**
     * resets the input field to scan the next mafi
     */
    const onSavePressed = async () => {
        if (!inventory || !mafiNr || !location) return;
        store.dispatch(setShowLoading(true));
        try {
            const payload: MafiLocationDto = {
                mafiNo: mafiNr,
                location: notation,
                lat: location.lat,
                long: location.long,
            };

            await RoTrailInventoryService.instance.addMafiToInventory(inventory, payload);
            SnackbarUtil.success(t("INVENTORY.ADDED"));
            navigate(-1);
        } catch (e) {
            if (e.response.status === 400) {
                SnackbarUtil.error(t("INVENTORY.ALREADY_ADDED"));
            } else {
                SnackbarUtil.error(t("INVENTORY.ERROR"));
            }
        } finally {
            store.dispatch(setShowLoading(false));
        }
    }

    return (
        <Box sx={{
            height: "100%", display: "flex", flexDirection: "column",
            backgroundColor: theme.palette.surface.main, p: 1.5, overflowY: "auto"
        }}>
            <ScanMafiNumberComponent disabled={!!activeMafi}
                                     mafiNr={mafiNr}
                                     selectTagEvent={(tag: RFIDScanResult) => {
                                         setMafiNr(tag.parsedId);
                                     }}/>

            {isEmpty && <EmptyMafiPlaceholder mafiNr={mafiNr!}/>}

            {!activeMafi && !isEmpty && <Box>
                <Typography sx={{fontSize: '14px', fontWeight: 500, pt: 1.5, lineHeight: '14px'}}>
                    {t('SET_PLACE.DESCRIPTION')}
                </Typography>

                <Button
                    onClick={openSetPlace}
                    {...defaultButtonProps}
                    sx={{...defaultButtonStyle, mt: 3}}
                    disabled={mafiNr.length <= 0}>
                    {t('SET_PLACE.SET_PLACE')}
                </Button>

                <Button {...secondaryButtonProps} sx={{...secondaryButtonStyle, mt: 2}} onClick={onCancelPressed}>
                    {t('GENERAL.CANCEL')}
                </Button>
            </Box>}

            {activeMafi && <Box sx={{display: "flex", flexDirection: "column", width: '100%', pt: 0}}>
                <Box sx={{my: 2, backgroundColor: theme.palette.white.main, borderRadius: "6px", px: 1.5, pb: 3, pt: 2}}>
                    <Typography sx={{fontSize: '16px', fontWeight: '600', mb: 1.5 }}>{ t("SET_PLACE.LOCATION_SYSTEM")} </Typography>
                    <Typography variant={"h6"}>{activeMafi!.mafiLocation.location}</Typography>
                    <Box sx={{mt: 2.5}}>
                        <LiveMafiLocationComponent
                            dangerousGood={activeMafi!._hasDangerousData}
                            latitude={mdeLatitude}
                            longitude={mdeLongitude}
                            lastSavedLocation={lastSavedLocation}
                            onLocationChangedCallback={(l) => setLocation(l)}
                            onNotationChangedCallback={(v, n) => notationChanged(v, n)}
                        />
                    </Box>
                </Box>
                <Box>
                    <Button
                        {...defaultButtonProps}
                        sx={{...defaultButtonStyle}}
                        onClick={onSavePressed}
                        disabled={!location || !activeMafi || !notation || !locationValid}>
                        {t('GENERAL.USE')}
                    </Button>

                    <Button
                        {...secondaryButtonProps}
                        sx={{...secondaryButtonStyle, mt: 2}}
                        onClick={onCancelSetPlace}>
                        {t('GENERAL.CANCEL')}
                    </Button>
                </Box>
            </Box>}
        </Box>
    );
};

export default InventoryScanView;
