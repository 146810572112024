import {MafiInformation} from "@blg/blg-core";
import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Box,
    Button,
    Grid,
    MenuItem,
    SelectChangeEvent, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../routes/routes";
import {
    defaultButtonProps,
    defaultButtonStyle,
    secondaryButtonProps,
    secondaryButtonStyle
} from "../../../styles/defaultStyles";
import UnionTypeDialog from "../../../components/union/unionTypeDialog.component";
import {MafiType} from "../../../models/MafiType";
import {store} from "../../../store/store";
import {setActiveTutorial, setActiveView} from "../../../store/slices/global";
import {clearCurrentMafi, setCurrentMafi, setIsNewMafi} from "../../../store/slices/union";
import {mafiTypes} from "../../../misc/mafiTypes";
import {LashingCategory} from "../../../enum/lashingCategory.enum";
import {useAppSelector} from "../../../store/hooks";
import {SnackbarUtil} from "@blg/blg-core/lib/esm/components";
import {theme} from "../../../styles/theme";

const defaultInformation: MafiInformation = MafiInformation.parseFromObject({
    id: '',
    shipOwner: '',
    lashingMethod: '',
    tara: '',
    typeCode: 'ROLLTRAILER',
    loadStatus: "LEER",
    payload: '',
    length: '',
    goodsData: [],
    mafiProperties: {
        pool: false,
        fixedMafi: false,
        serviceMafi: false,
        emptyMafi: false,
    },
    width: '',
    weightMaxLoad: '',
    mafiOwnerCode: '',
});

const UnionEnterDataView: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    let validator: ValidatorForm;

    const [information, setInformation] = useState<MafiInformation>(defaultInformation);
    const [showTypeDialog, setShowTypeDialog] = useState<boolean>(false);
    const storeInformation = useAppSelector(state => state.union).currentMafi;
    const isNewMafi = useAppSelector(state => state.union).isNewMafi;

    useEffect(() => {
        loadMafiData();
    }, []);

    useEffect(() => {
        if (validator) {
            validator.isFormValid(false);
        }
    }, [validator!]);

    const loadMafiData = async () => {
        if (!storeInformation) return;

        if (isNewMafi) {
            // @ts-ignore
            setInformation({...defaultInformation, mafiNo: storeInformation.mafiNo});
        } else {
            const lashingMethod = storeInformation.lashingMethod ?? defaultInformation.lashingMethod;
            const typeCode = storeInformation.typeCode ?? defaultInformation.typeCode;
            const mafiOwnerCode = storeInformation.mafiOwnerCode ?? defaultInformation.mafiOwnerCode;
            const length = storeInformation.length ?? defaultInformation.length;
            const width = storeInformation.width ?? defaultInformation.width;
            // @ts-ignore
            setInformation({...storeInformation, lashingMethod, typeCode, mafiOwnerCode, width, length});
        }
    }

    /**
     * is called when the button is pressed, saves the information and resets the
     * input fields
     */
    async function onSavePressed(): Promise<void> {
        if (!await validator.isFormValid(true)) {
            SnackbarUtil.error(t('UNION.VALIDATION_ERROR'));
            return;
        }
        store.dispatch(setCurrentMafi(information));
        navigate(ROUTES.UNION_SAVE);
    }

    /**
     * is called when the button cancel button is pressed. Return to home
     */
    function onCancelPressed(): void {
        store.dispatch(clearCurrentMafi());
        store.dispatch(setIsNewMafi(null));
        navigate(-1);
    }

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveTutorial({tutorial: "UNION_MINIMAL", show: true}));
        store.dispatch(setActiveView({text: t('TASK_SELECTION.UNION_ENTER_DATA'), params: null}));
    }, []);

    /**
     * is called when an information has changed,
     * stores the information model with the updated information
     * @param event
     * @param options
     */
    function onInformationEntered(event: ChangeEvent<HTMLInputElement>, options: { isNumber?: boolean }): void {
        const key: string = event.target.name;
        const data = Object.assign({}, information);

        if (options.isNumber) {
            event.target.value = event.target.value.replace(/^0+/, '');
            // @ts-ignore
            data[key as keyof StateData] = event.target.value !== "" ? Number(event.target.value) : 0;
        } else {
            // @ts-ignore
            data[key as keyof StateData] = event.target.value;
        }

        setInformation(MafiInformation.parseFromObject({
            ...data,
            [key]: event.target.value
        }));
    }

    /**
     * is called when an information has changed inside a select field,
     * stores the information model with the updated information
     * @param event
     */
    function onSelectChanged<T>(event: SelectChangeEvent<T>): void {
        const key: string = event.target.name;
        const data = Object.assign({}, information);
        // @ts-ignore
        data[key as keyof StateData] = event.target.value;

        setInformation(MafiInformation.parseFromObject({
            ...data,
            [key]: event.target.value
        }));
    }

    /**
     * is called when the user selected a type inside the type dialog,
     * closes the dialog and sets the typeCode as selected type
     * @param type
     */
    const onTypeSelected = (type: MafiType | null) => {
        setShowTypeDialog(false);
        const data = MafiInformation.parseFromObject({
            ...information,
            typeCode: type?.code
        });
        setInformation(data);
    }

    /**
     * is called when the user wants to close the dialog, sets closing flag
     */
    const onTypeDialogClosed = () => {
        setShowTypeDialog(false);
    }

    /**
     * gets the mafiType based on the selected type code
     */
    const getCurrentType = () => {
        return mafiTypes.find((t) => t.code === information.typeCode);
    }

    return (
        <Box sx={{
            height: "100%", display: "flex", flexDirection: "column", p: 1.5,
            overflowY: "auto"
        }}>
            <ValidatorForm onSubmit={onSavePressed} ref={node => (validator = node!)}>
                <Box sx={{width: "100%", backgroundColor: theme.palette.white.main, borderRadius: "6px", p: 1.5}}>
                    <Typography sx={{ textAlign: "center", mb: 1 }} variant={"h6"}>
                        {information.mafiNo}
                    </Typography>
                    <SelectValidator
                        label={t('MAFI_INFORMATION.TYPE')}
                        name={"typeCode"}
                        sx={{width: "100%", mb: 1}}
                        value={information.typeCode}
                        onChange={(e) => onSelectChanged(e as SelectChangeEvent<SelectValidator>)}
                        validators={["required"]}
                        errorMessages={[t("ERROR.REQUIRED")]}
                    >
                        {mafiTypes.map((type) => <MenuItem
                            value={type.code}
                            key={type.code}>{t(type.title)}
                        </MenuItem>)}
                    </SelectValidator>

                    <SelectValidator
                        label={t('MAFI_INFORMATION.LASHING_METHOD')}
                        name={"lashingMethod"}
                        sx={{width: "100%", mb: 1}}
                        value={information.lashingMethod}
                        onChange={(e) => onSelectChanged(e as SelectChangeEvent<SelectValidator>)}
                        validators={["required"]}
                        errorMessages={[t("ERROR.REQUIRED")]}
                    >
                        {Object.keys(LashingCategory).filter((i) => {
                            return isNaN(Number(i))
                        }).map((method) => <MenuItem key={method} value={method}>
                            {t(`LASHING_METHOD.${method}`)}
                        </MenuItem>)}
                    </SelectValidator>
                    <Grid container sx={{mb: 1}}>
                        <Grid item xs={6}>
                            <SelectValidator
                                name={"length"}
                                sx={{width: "100%", pr: 2}}
                                value={information.length === 0 ? "" : information.length}
                                onChange={(e) => onSelectChanged(e as SelectChangeEvent<SelectValidator>)}
                                validators={["required"]}
                                errorMessages={[t("ERROR.REQUIRED")]}
                                label={t('MAFI_INFORMATION.LENGTH')}
                            >
                                <MenuItem value={20}>{t('GENERAL.FOOT', {value: 20})}</MenuItem>
                                <MenuItem value={30}>{t('GENERAL.FOOT', {value: 30})}</MenuItem>
                                <MenuItem value={40}>{t('GENERAL.FOOT', {value: 40})}</MenuItem>
                                <MenuItem value={60}>{t('GENERAL.FOOT', {value: 60})}</MenuItem>
                                <MenuItem value={62}>{t('GENERAL.FOOT', {value: 62})}</MenuItem>
                                <MenuItem value={70}>{t('GENERAL.FOOT', {value: 70})}</MenuItem>
                                <MenuItem value={72}>{t('GENERAL.FOOT', {value: 72})}</MenuItem>
                                <MenuItem value={82}>{t('GENERAL.FOOT', {value: 82})}</MenuItem>
                                <MenuItem value={90}>{t('GENERAL.FOOT', {value: 90})}</MenuItem>
                                <MenuItem value={92}>{t('GENERAL.FOOT', {value: 92})}</MenuItem>
                            </SelectValidator>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectValidator
                                name={"width"}
                                sx={{width: "100%"}}
                                value={information.width}
                                onChange={(e) => onSelectChanged(e as SelectChangeEvent<SelectValidator>)}
                                validators={["required"]}
                                errorMessages={[t("ERROR.REQUIRED")]}
                                label={t('MAFI_INFORMATION.WIDTH')}
                            >
                                <MenuItem value={0}>{'Breite unbekannt'}</MenuItem>
                                <MenuItem value={280}>{t('GENERAL.CENTI_METER', {value: 280})}</MenuItem>
                                <MenuItem value={300}>{t('GENERAL.CENTI_METER', {value: 300})}</MenuItem>
                            </SelectValidator>
                        </Grid>
                    </Grid>
                    <TextValidator
                        sx={{mb: 1, width: "100%"}}
                        onChange={(e) => onInformationEntered(e as ChangeEvent<HTMLInputElement>, {isNumber: true})}
                        name={"weightMaxLoad"}
                        value={information.weightMaxLoad === 0 ? "" : information.weightMaxLoad}
                        label={t('MAFI_INFORMATION.PAYLOAD')}
                        validators={["required", "minNumber:1000", "maxNumber:999999"]}
                        type={"number"}
                        errorMessages={[t("ERROR.REQUIRED"), t("ERROR.MIN_NUMBER", {min: 1000}), t("ERROR.MAX_NUMBER", {max: 999999})]}
                    />
                    <TextValidator
                        sx={{mb: 1, width: "100%"}}
                        onChange={(e) => onInformationEntered(e as ChangeEvent<HTMLInputElement>, {isNumber: true})}
                        name={"tara"}
                        type={"number"}
                        value={information.tara}
                        label={t('MAFI_INFORMATION.TARA')}
                        validators={["required", "minNumber:1000", "maxNumber:999999"]}
                        errorMessages={[t("ERROR.REQUIRED"), t("ERROR.MIN_NUMBER", {min: 1000}), t("ERROR.MAX_NUMBER", {max: 999999})]}
                    />
                </Box>
                {/* button to go to the save page */}
                <Button
                    {...defaultButtonProps}
                    sx={{...defaultButtonStyle, mt: 3}}
                    type="submit"
                >{t("GENERAL.NEXT")}</Button>

                {/* button to cancel the editing and go back to the scan page */}
                <Button
                    {...secondaryButtonProps}
                    sx={{...secondaryButtonStyle, mt: 2}}
                    onClick={onCancelPressed}>
                    {t('GENERAL.CANCEL')}
                </Button>
            </ValidatorForm>
            {/* type dialog that allows to select a mafi type */}
            {showTypeDialog && <UnionTypeDialog
                showDialog={showTypeDialog}
                onTypeSelected={onTypeSelected}
                initialType={getCurrentType()}
                onClose={onTypeDialogClosed}
            />}
        </Box>
    );
};

export default UnionEnterDataView;
