import React, {useState} from "react";
import {Box, CircularProgress, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {MafiLoadStatusDto} from "../../models/api/dto/MafiLoadStatusDto";
import {SnackbarUtil} from "@blg/blg-core/lib/esm/components";
import {useTranslation} from "react-i18next";
import {defaultStyles} from "../../styles/defaultStyles";
import {useTheme} from "@mui/material/styles";
import { MafiInformation, RoTrailMafiService } from "@blg/blg-core";

interface MafiInformationComponentProps {
    /**
     * information used to check the initial loading status
     */
    information: MafiInformation,
    /**
     * disables the loading status (user is unable to change the value)
     */
    disabled?: boolean,
    /**
     * when set to `true`, the parent is notified about status changes and has to update the
     * loading status on the api, if set to `false` this component set the loading status via
     * endpoint by itself
     */
    isParentUpdating?: boolean,
    /**
     * callback when the status has changed
     * @param status
     */
    onStatusChanged?: (status: 'VOLL' | 'LEER') => void,
}

const LoadingStatusComponent = (props: MafiInformationComponentProps) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const [isLoading, setLoading] = useState<boolean>(false);
    const isDisabled = props.disabled ?? false;

    const toggleButtonStyle = {
            selected: {
                fontWeight: "bold",
                color: isDisabled ? "grey" : theme.palette.text.primary
            },
            deselected: {
                fontWeight: 400,
                color: isDisabled ? "grey" : theme.palette.text.primary
            }
    }

    /**
     * is called when the load status has changed, updates the hook and decides to either notify
     * the parent about status change or update the loading status for the mafi by itself
     * @param event
     * @param loadingValue
     */
    const onStatusChanged = async (event: React.MouseEvent<HTMLElement>, loadingValue: "VOLL" | "LEER") => {
        if (!loadingValue) {
            return;
        }

        // calls callback function to notify parent about status change
        props.onStatusChanged?.call(null, loadingValue);

        // if `isParentUpdating` flag is set, the parent is responsible for updating the status on
        // server side, therefore we just return here
        if (props.isParentUpdating) return;

        // updates the load status for the mafi on the server by creating payload and calling
        // endpoint
        const payload: MafiLoadStatusDto = {
            mafiNo: props.information.mafiNo,
            loadStatus: loadingValue,
        };
        try {
            setLoading(true);
            await RoTrailMafiService.instance.setMafiLoadStatus(payload);
            SnackbarUtil.success(t('LOAD_STATUS.SUCCESS'));
        } catch (_) {
            SnackbarUtil.error(t('LOAD_STATUS.ERROR'));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box
            style={defaultStyles.contentBox}
            sx={{p: 1.5}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                    {t('LOAD_STATUS.STATUS')}
                </Typography>
                {isLoading && <CircularProgress size={16} sx={{ml: 2}}/>}
            </Box>

            <Box sx={{display: 'flex', gap: '10px', width: '100%'}}>
                <ToggleButtonGroup
                    fullWidth
                    color="primary"
                    value={props.information.loadStatus}
                    exclusive
                    disabled={props.disabled}
                    onChange={onStatusChanged}
                >
                    <ToggleButton value="VOLL">
                        <Typography
                            sx={props.information.loadStatus === "VOLL" ?
                                toggleButtonStyle.selected : toggleButtonStyle.deselected}>
                            VOLL
                        </Typography>
                    </ToggleButton>
                    <ToggleButton value="LEER">
                        <Typography
                            sx={props.information.loadStatus === "LEER" ? toggleButtonStyle.selected : toggleButtonStyle.deselected}>
                            LEER
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
};

export default LoadingStatusComponent;
