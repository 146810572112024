import {MafiInformation, RoTrailMafiService} from "@blg/blg-core";
import React, {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../routes/routes";
import {store} from "../../../store/store";
import {setActiveView, setShowLoading} from "../../../store/slices/global";
import MafiInformationComponent from "../../../components/mafi/mafiInformation.component";
import {useParams} from "react-router";
import LoadingStatusComponent from "../../../components/mafi/loadingStatus.component";
import {SnackbarUtil} from "@blg/blg-core/lib/esm/components";
import EmptyMafiPlaceholder from "../../../components/mafi/emptyMafiPlaceholder.component";
import {defaultStyles, secondaryButtonProps, secondaryButtonStyle} from "../../../styles/defaultStyles";

const MafiInfoDetailsView: React.FC = () => {
    const {mafiNr} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [activeMafi, setActiveMafi] = useState<MafiInformation | null>(null);
    const [loadedData, setLoadedData] = useState<boolean>(false);

    useEffect(() => {
        store.dispatch(setActiveView({text: t('TASK_SELECTION.MAFI_INFO'), params: null}));
        loadMafiData();
    }, []);

    const loadMafiData = async () => {
        store.dispatch(setShowLoading(true));
        try {
            // searches for the mafi based on the number and gets the details of the mafi based on that
            const response = await RoTrailMafiService.instance.getByMafiNo(mafiNr!);
            if (response && response.data) {
                const mafi = MafiInformation.parseFromObject(response.data);
                setActiveMafi(mafi);
            } else {
                SnackbarUtil.error(t('MAFI_INFORMATION.EMPTY', {'value': mafiNr}));
                navigate(ROUTES.MAFI_INFO);
            }
        } catch (e) {
            if (e.response && e.response.status !== 401) {
                SnackbarUtil.error(t('MAFI_INFORMATION.ERROR', {'value': mafiNr}));
                navigate(ROUTES.MAFI_INFO);
            }
        } finally {
            setLoadedData(true);
            store.dispatch(setShowLoading(false));
        }
    }

    function goBack() {
        navigate((-1));
    }

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: '100%',
            minHeight: '100%',
            p: 1.5,
            height: "100%",
            overflowY: "auto"
        }}>
            {!activeMafi && loadedData && <EmptyMafiPlaceholder mafiNr={mafiNr!}/>}
            {activeMafi && <Box sx={{display: "flex", flexDirection: "column", width: '100%'}}>
                <MafiInformationComponent information={activeMafi!}/>
                <Box style={defaultStyles.contentBox} sx={{p: 1.5, mt: 1}}>
                    <Typography sx={{
                        fontSize: '16px',
                        fontWeight: '600',
                        mb: 1.5
                    }}>{t("SET_PLACE.LOCATION_SYSTEM")} </Typography>
                    <Typography variant={"h6"}>{activeMafi!.mafiLocation.location}</Typography>
                </Box>
                <Box sx={{mt: 1}}>
                    <LoadingStatusComponent disabled information={activeMafi!}/>
                </Box>
                <Button
                    {...secondaryButtonProps}
                    sx={{...secondaryButtonStyle, mt: 3}}
                    onClick={goBack}>
                    {t('GENERAL.CANCEL')}
                </Button>
            </Box>}
        </Box>
    );
};

export default MafiInfoDetailsView;
