import {DateTime} from "luxon";
import React, {useEffect, useRef, useState} from "react";
import {hexToAscii} from "../../misc/rfidHelper";
import {useAppSelector} from "../../store/hooks";
import {setScannerConnection, setShowLoading, setShowLoadingText} from "../../store/slices/global";
import {store} from "../../store/store";
import ReconnectRFIDDialog from "../dialog/reconnectRFIDDialog.component";
import {RFIDScanResult} from "../dialog/rfidTagSelectionDialogComponent";
import {Emitter, SnackbarUtil} from "@blg/blg-core";
import {useTranslation} from "react-i18next";

const RFIDConnectionComponent: React.FC = () => {
    const {t} = useTranslation();

    const globalStore = useAppSelector(state => state.global);
    const scannerConnection = globalStore.scannerConnection;
    const rfidConnector = useRef<any>();

    const [showConnectionDialog, setShowConnectionDialog] = useState(false);
    const [lastMessageTime, setLastMessageTime] = useState<Date | null>(null);

    useEffect(() => {
        if (window.cordova && window.cordova.plugins) {
            if (!scannerConnection) {
                store.dispatch(setShowLoading(true));
                store.dispatch(setShowLoadingText("Verbindung wird hergestellt..."));
            }

            rfidConnector.current = window.cordova.plugins.RFID;
            rfidConnector.current.init(initCallback, initErrorCallback);
        }

        return () => {
            console.log("RFIDConnectionComponent unmounted");
            if (rfidConnector.current) {
                rfidConnector.current.disconnect();
                store.dispatch(setScannerConnection(false));
            }
        }
    }, []);

    const initCallback = (message: string) => {
        store.dispatch(setShowLoading(false));
        store.dispatch(setShowLoadingText(undefined));
        console.log('initCallback', message);

        if (message.includes("404")) {
            SnackbarUtil.error(t("ERROR.NO_SCANNER"));
            return;
        } else if (message.includes("failed") || message.includes("Failed")) {
            initErrorCallback(message);
            return;
        } else if (message.includes("Disconnected")) {
            store.dispatch(setShowLoading(true));
            store.dispatch(setShowLoadingText("Verbindung wird erneut hergestellt..."));
            return;
        } else if (message.includes("Connected to")) {
            store.dispatch(setShowLoadingText(undefined));
            store.dispatch(setShowLoading(false));
        } else if (message.includes("Akku")) {
            if (!lastMessageTime || DateTime.now().diff(DateTime.fromJSDate(lastMessageTime), 'seconds').seconds > 15) {
                setLastMessageTime(new Date());

                setTimeout(() => {
                    store.dispatch(setShowLoading(true));
                    store.dispatch(setShowLoadingText("Verbindung wird hergestellt..."));
                }, 3000);
            }
        }

        store.dispatch(setScannerConnection(true));
        setShowConnectionDialog(false);
        rfidConnector.current.listenInventory(tagResultCallback, tagErrorCallback);
    };

    const connectToReader = () => {
        if (!scannerConnection) {
            store.dispatch(setShowLoading(true));
            store.dispatch(setShowLoadingText("Verbindung wird hergestellt..."));
        }

        rfidConnector.current.connect();
    }

    const initErrorCallback = (message: string) => {
        store.dispatch(setScannerConnection(false));
        if (!message.includes("null")) {
            SnackbarUtil.warning(message);
        }
        console.log('initErrorCallback', message);
        setShowConnectionDialog(true);
    };

    const tagResultCallback = (message: string) => {
        console.log("tagResultCallback", message);
        const json: Array<RFIDScanResult> = JSON.parse(message);
        const updatedJSON: Array<RFIDScanResult> = json.map(tag => {
            const decoded = hexToAscii(tag.id);
            return {
                id: tag.id,
                parsedId: decoded,
                rssi: tag.rssi,
                // @ts-ignore
                pcValue: (tag.pcValue as number).toString(16)
            }
        });

        const filteredEmptyTags = updatedJSON.filter(tag => tag.parsedId !== "" && tag.id !== ""); // && tag.rssi !== 0);
        if (updatedJSON.length !== filteredEmptyTags.length) {
            SnackbarUtil.warning("RFID-Tags mit leerer Tag-ID gefunden");
        }
        console.log('updated json stuff', filteredEmptyTags);
        Emitter.emit("TAGS", filteredEmptyTags);
    };

    const tagErrorCallback = (message: string) => {
        SnackbarUtil.warning(message);
        console.log('tagErrorCallback', message)
    }

    return (
        <>
            <ReconnectRFIDDialog showDialog={showConnectionDialog} reconnectEvent={() => {
                setTimeout(() => {
                    connectToReader();
                }, 500);
                setShowConnectionDialog(false);
            }} closeEvent={() => {
                setShowConnectionDialog(false);
            }}/>
        </>
    );
};

export default RFIDConnectionComponent;
