import React, {useEffect, useState} from 'react';

import {Box, Backdrop} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {Outlet, useNavigate} from "react-router-dom";
import RFIDConnectionComponent from "../components/rfid/rfidConnection.component";
import {ROUTES} from "../routes/routes";
import LoadingImage from "../assets/images/loading-animation.svg";
import {useAppSelector} from "../store/hooks";

import {useLocation} from "react-router-dom"
import { logToFile } from '@blg/blg-core';

function Login() {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    let timer: NodeJS.Timeout;

    const token = useAppSelector(state => state.auth).token;

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);

    useEffect(() => {
        setShowLoadingAnimation(true);

        return () => {
            setShowLoadingAnimation(false);
            if (timer) {
                clearInterval(timer);
            }
        }
    }, []);

    useEffect(() => {
        if (token) {
            timer = setTimeout(() => {
                setShowLoadingAnimation(false);

                if (isLoggedIn) {
                    return;
                }

                setIsLoggedIn(true);
                if (location.pathname === "/login") {
                    return;
                }

                if (location.pathname === "/") {
                    navigate(ROUTES.HOME);
                } else {
                    navigate(location.pathname);
                }
            }, 1000);
        } else {
            timer = setTimeout(() => {
                logToFile("Go to login -> token is missing");
                setShowLoadingAnimation(false);
                navigate(ROUTES.LOGIN);
            }, 1000);

        }
    }, [token]);

    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main, height: "100vh", width: "100%",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
            <Box sx={{position: "absolute", top: 0, left: 0}}>
                <Backdrop
                    sx={{
                        backgroundColor: "rgba(222,224,233,0.9)",
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={showLoadingAnimation}
                >
                    <object type="image/svg+xml" style={{width: 275, height: 275}} data={LoadingImage}>svg-animation
                    </object>
                </Backdrop>
            </Box>
            <RFIDConnectionComponent/>
            <Outlet/>
        </Box>
    );
}

export default Login;
