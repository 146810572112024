import {Parseable} from "@blg/blg-core";

export default class MafiOwner extends Parseable {
    public id!: number;
    public mafiOwnerCode!: string;
    public mafiOwnerText!: string;

    public static parseFromObject(object: any): MafiOwner {
        const owner = new MafiOwner();
        Object.assign(owner, object);
        return owner;
    }

}
