import {Emitter, EmitterEvents, parseNotations, RoTrailNotationService, SnackbarUtil} from '@blg/blg-core';
import {calcCoordsDistance} from "@blg/blg-core/lib/esm/misc/roTrail/parseAPIDataHelper";
import {Backdrop, Box, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Outlet, useNavigate} from "react-router-dom";
import LoadingImage from "../assets/images/loading-animation.svg";
import NavigationComponent from "../components/navigation/navigation.component";
import LocalForageHelper from "../misc/localforageHelper";
import {ROUTES} from "../routes/routes";
import {useAppSelector} from "../store/hooks";
import {setRefreshToken, setToken} from "../store/slices/auth";
import {setMDEPosition} from "../store/slices/custom";
import {store} from "../store/store";

const HomeView: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const logoutTimer = useRef<NodeJS.Timeout | null>(null);

    const [watchId, setWatchID] = useState<number | null>(null);
    const [tempLocation, setTempLocation] = useState<GeolocationPosition | null>(null);

    const driverLatitude = useAppSelector(state => state.custom).latitude;
    const driverLongitude = useAppSelector(state => state.custom).longitude;
    const globalStore = useAppSelector(state => state.global);
    const refreshToken = useAppSelector(state => state.auth).refreshToken;

    const showLoading = globalStore.showLoading;
    const loadingText = globalStore.loadingText;

    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);

    useEffect(() => {

        checkLogin();
        updateNotations();

        Emitter.on(EmitterEvents.TOKEN_EXPIRED, () => {
            console.log("token expired");
            SnackbarUtil.error(t("ERROR.SESSION_EXPIRED"));
            navigate(ROUTES.LOGIN, {replace: true});
        });

        Emitter.on(EmitterEvents.TOKEN_REFRESHED, (payload: { accessToken: string, refreshToken: string }) => {
            store.dispatch(setRefreshToken(payload.refreshToken));
            store.dispatch(setToken(payload.accessToken));
        });

        // Watch the driver position and save it in the store
        const id = navigator.geolocation.watchPosition((position) => {
                if (position.coords.latitude && position.coords.longitude) {
                    setTempLocation(position);
                }
            },
            (error) => {
                console.log(error);
            }, {enableHighAccuracy: true, maximumAge: 5000});

        setWatchID(id);

        return () => {
            Emitter.removeAllListeners();

            if (watchId) {
                navigator.geolocation.clearWatch(watchId);
            }
        }
    }, []);

    useEffect(() => {
        setShowLoadingAnimation(showLoading);
    }, [showLoading]);

    useEffect(() => {
        if (tempLocation) {
            checkLocation(tempLocation);
        }
    }, [tempLocation])

    const checkLocation = (position: GeolocationPosition) => {
        if (driverLongitude && driverLatitude) {
            const distance = calcCoordsDistance({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                },
                {latitude: driverLatitude, longitude: driverLongitude});
            // Only update the location if the distance is bigger than x meters
            if (distance > 5) {
                store.dispatch(setMDEPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        accuracy: position.coords.accuracy
                    }
                ));
            }
        } else if (position.coords.longitude && position.coords.latitude) {
            store.dispatch(setMDEPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    accuracy: position.coords.accuracy
                }
            ));
        }
    }

    const updateNotations = async () => {
        try {
            // Sync all the notations after the login
            const notations = await RoTrailNotationService.instance.getAllNotations();
            await LocalForageHelper.instance.setAllMafiNotations(notations);
            const result = parseNotations(notations);

            await LocalForageHelper.instance.setParsedNotations(result);
        } catch (e) {

        }
    }

    const checkLogin = () => {
        logoutTimer.current = setTimeout(() => {
            const tokenIsValid = validateToken(refreshToken);
            if (!tokenIsValid) {
                goToLogin();
            } else {
                checkLogin();
            }
        }, 60000);
    }

    const goToLogin = () => {
        localStorage.removeItem(`persist:${process.env.REACT_APP_STORE_KEY}`);
        setShowLoadingAnimation(false);
        navigate(ROUTES.LOGIN);
    };

    /**
     * Check if the token is valid
     * @param token
     */
    const validateToken = (token?: string): boolean => {
        if (!token) {
            return false;
        }

        const arrayToken = token.split('.');
        const tokenPayload = JSON.parse(atob(arrayToken[1]));
        return !(new Date().getTime() >= (new Date(tokenPayload?.exp * 1000).getTime()));
    }

    return (
        <Box sx={{display: "flex", height: "100vh", width: "100%"}}>
            <Box sx={{position: "absolute", top: 0, left: 0}}>
                <Backdrop
                    sx={{
                        backgroundColor: "rgba(222,224,233,0.9)",
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: "flex", flexDirection: "column"
                    }}
                    open={showLoadingAnimation}
                >
                    <object type="image/svg+xml" style={{width: 275, height: 275}} data={LoadingImage}>svg-animation
                    </object>
                    {loadingText &&
                        <Typography variant={"h6"}>
                            {loadingText}
                        </Typography>
                    }
                </Backdrop>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", width: "100%", overflowY: "hidden"}}>
                <NavigationComponent/>
                <Box sx={{
                    backgroundColor: "#D1E0F9",
                    height: "100%",
                    width: "100%",
                    boxSizing: "border-box",
                    maxHeight: "calc(100vh - 56px)"
                }}>
                    <Outlet/>
                </Box>
            </Box>
        </Box>
    );
};

export default HomeView;
