import * as React from 'react';
import {Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, ButtonBase} from "@mui/material";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";

export interface RFIDScanResult {
    id: string,
    parsedId: string,
    rssi: number,
    pcValue: string
}

export interface RfidSelectionProps {
    showDialog: boolean,

    selectEvent(rfid: RFIDScanResult): any,

    closeEvent(): any,

    tags: RFIDScanResult[]
}

const RfidTagSelectionDialogComponent = (props: RfidSelectionProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(props.showDialog);
    }, [props.showDialog]);

    const tagElements = props.tags.map((tag) => {
        return (<Box
            sx={{ mb: 2 }}
            component={ButtonBase}
            // Small delay to show the ripple effect
            onClick={() => {
                setTimeout(() => {
                    props.selectEvent(tag);
                }, 250);
            }}
            key={tag.parsedId}>
            <Box sx={{ display: "flex", flexDirection: "column", p: 3, width: "100%",
                color: theme.palette.primary.main, borderRadius: "6px",
                border: `1px solid ${theme.palette.primary.main}` }}>
                <Typography variant={"body2"}>
                    {tag.parsedId}
                </Typography>
            </Box>
        </Box>)
    });

    return (
        <Box>
            <Dialog
                fullWidth
                open={open}
            >
                <DialogTitle>
                    Tag-Auswahl
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <Typography sx={{ mb: 3 }} variant={"body2"}>Folgende Tags wurden gefunden:</Typography>
                        {tagElements}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button
                        sx={{ color: `${theme.palette.primary.main}` }}
                        onClick={props.closeEvent}>
                        {t("GENERAL.CANCEL")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default RfidTagSelectionDialogComponent;
