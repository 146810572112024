import React, {useEffect, useState} from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {store} from "../../../store/store";
import {setActiveTutorial, setActiveView, setShowLoading} from "../../../store/slices/global";
import {ROUTES} from "../../../routes/routes";
import Inventory from "../../../models/api/Inventory";
import {generatePath} from "react-router";
import {defaultButtonProps, defaultButtonStyle, defaultSelectProps} from "../../../styles/defaultStyles";
import { RoTrailInventoryService } from "@blg/blg-core";

const InventoryView: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    /**
     * the current selected inventory
     */
    const [inventoryId, setInventoryId] = useState<string>('');

    /**
     * list of different inventories
     */
    const [inventories, setInventories] = useState<Inventory[]>([])

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveTutorial({tutorial: "FUNCTION", show: false}));
        store.dispatch(setActiveView({text: t('TASK_SELECTION.INVENTORY'), params: null}));

        loadInventories();
    }, []);

    /**
     * loads the inventories from the api to show inside select field
     */
    const loadInventories = async () => {
        store.dispatch(setShowLoading(true));
        try {
            const response = await RoTrailInventoryService.instance.getInventories();
            const data = Inventory.parseFromArray(response.data) as Inventory[];
            setInventories(data);

            if (data.length > 0) {
                setInventoryId(data[0].id);
                onContinuePressed();
            }
        } finally {
            store.dispatch(setShowLoading(false));
        }
    }

    /**
     *
     * @param data
     */
    function onChange(data: any): void {
        const value = data.target.value;
        setInventoryId(value);
    }

    function onContinuePressed() {
        const inventory = inventories.find((i) => i.id === inventoryId);
        if (!inventory) return;
        store.dispatch(setActiveView({text: inventory.inventoryName, params: null}));
        const path = generatePath(ROUTES.INVENTORY_SCAN, {inventoryId: String(inventoryId)});
        navigate(path);
    }

    return (
        <Box sx={{
            height: "100%", display: "flex", alignItems: "center", flexDirection: "column",
            backgroundColor: theme.palette.surface.main, pt: 7.5, px: 5
        }}>
            <FormControl variant={"filled"} sx={{width: '100%'}}>
                <InputLabel id={"select-inventory-label"}>{t('INVENTORY.PICK')}</InputLabel>
                <Select
                    fullWidth
                    onChange={onChange}
                    {...defaultSelectProps}
                    value={inventoryId}
                    labelId={"select-inventory-label"}
                >
                    {inventories.map((i) => <MenuItem key={i.id} value={i.id}>{i.inventoryName}</MenuItem>)}
                </Select>
            </FormControl>

            <Button
                {...defaultButtonProps}
                sx={{...defaultButtonStyle, mt: 2}}
                variant={"contained"}
                color={"primary"}
                onClick={onContinuePressed}
                disabled={inventoryId.length <= 0}>
                {t('GENERAL.NEXT')}
            </Button>
        </Box>
    );
};

export default InventoryView;
