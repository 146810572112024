export enum ROUTES {
    ROOT = "/",
    HOME = "/home",
    LOGIN = "/login",
    TASK_SELECTION = "/home/tasks",

    /**
     * union views
     */
    UNION = '/home/union',
    UNION_ENTER_DATA = '/home/union/enter-data',
    UNION_SAVE = '/home/union/save',
    UNION_ORDER_STACK = '/home/union/orderStack',

    /**
     * inventory views
     */
    INVENTORY = '/home/inventory',
    INVENTORY_SCAN = '/home/inventory/scan',
    INVENTORY_SCAN_DETAILS = '/home/inventory/scan/:inventoryId/details/:mafiNr',

    /**
     * set place views
     */
    SET_PLACE = '/home/set-place',
    SET_PLACE_DETAILS = '/home/set-place/details/:mafiNr',

    /**
     * stack
     */
    STACK_TASK = '/home/stack-task',
    STACK = '/home/stack',
    STACK_DETAILS = '/home/stack/:mafiNo',

    UNSTACK = '/home/unstack',
    UNSTACK_DETAILS = '/home/unstack/:mafiNo',

    /**
     * additional info views
     */
    ADDITIONAL_FUNCTIONS = '/home/additional-functions',
    MAFI_INFO = '/home/additional-functions/mafi-info',
    MAFI_INFO_DETAILS = '/home/additional-functions/mafi-info/details/:mafiNr',
    ENTER_DATA = '/home/enter-data'
}
