import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/routes";
import {useTheme} from "@mui/material/styles";

import EntStack from '../../assets/images/EntStack.png';
import Stack from '../../assets/images/Stack.png';
import {defaultButtonProps, defaultButtonStyle} from "../../styles/defaultStyles";

const StackOptionsView = () => {
	const theme = useTheme();
	const navigate = useNavigate();

	return <Box sx={{height: "100%", display: "flex", flexDirection: "column", py: 4, px: 5.5,
		backgroundColor: theme.palette.blueLight.main}}>
		<Box sx={{height: "100%", display: "flex", flexDirection: "column"}}>
			<Typography sx={{color: theme.palette.primary.main, fontSize: '22px', fontWeight: '600', mb: 2}}>
				Aufgabe auswählen
			</Typography>
			<Button
				onClick={() => navigate(ROUTES.STACK)}
				{...defaultButtonProps}
                sx={{...defaultButtonStyle, backgroundColor: theme.palette.white.main, height: '114px', mt: 2, width: '100%',
                    flexDirection: 'column', "&:hover": {backgroundColor: theme.palette.primary.main}, "&:hover p": { color: theme.palette.grey.A100 }}}>
				<img src={Stack} width={40} />
				<Typography sx={{color: theme.palette.primary.main}}>Stacken</Typography>
			</Button>

			<Button
				onClick={() => navigate(ROUTES.UNSTACK)}
				{...defaultButtonProps}
				sx={{...defaultButtonStyle, backgroundColor: theme.palette.white.main, height: '114px', mt: 2, width: '100%',
					flexDirection: 'column', "&:hover": {backgroundColor: theme.palette.primary.main}, "&:hover p": { color: theme.palette.grey.A100 }}}>
				<img src={EntStack} width={40} />
				<Typography sx={{color: theme.palette.primary.main}}>Entstacken</Typography>
			</Button>
		</Box>
	</Box>;
}

export default StackOptionsView;
