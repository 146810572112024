import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./slices/index";

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const persistConfig = {
  key: process.env.REACT_APP_STORE_KEY!,
  storage,
  blacklist: ["global"],
  timeout: 0,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const myStore = createStore(persistedReducer, composedEnhancer);

export const store = myStore;
export const persistor = persistStore(myStore);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
