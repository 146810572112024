import * as React from 'react';
import {Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button} from "@mui/material";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";

export interface MafiSelectionProps {
    showDialog: boolean,
    reconnectEvent(): void,
    closeEvent(): void,
}

const ReconnectRFIDDialog = (props: MafiSelectionProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(props.showDialog);
    }, [props.showDialog]);

    return (
        <Box>
            <Dialog
                fullWidth
                open={open}
            >
                <DialogTitle>
                    RFID-Verbindung fehlgeschlagen
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <Typography variant={"body2"}>
                            Es konnte keine Verbindung zum RFID-Lesegerät hergestellt werden
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button
                        sx={{ color: `${theme.palette.primary.main}` }}
                        onClick={props.reconnectEvent}>
                        {t("GENERAL.RECONNECT")}
                    </Button>
                    <Button
                        sx={{ color: `${theme.palette.primary.main}` }}
                        onClick={props.closeEvent}>
                        {t("GENERAL.CANCEL")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ReconnectRFIDDialog;
