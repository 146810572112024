import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {saveMafiStack} from "../../../misc/mafiHelper";
import {ROUTES} from "../../../routes/routes";
import {store} from "../../../store/store";
import {setActiveTutorial, setActiveView, setShowBackButton, setShowLoading} from "../../../store/slices/global";
import ScanMafiNumberComponent from "../../../components/mafi/scanMafiNumber.component";
import {useAppSelector} from "../../../store/hooks";
import {
    defaultButtonProps,
    defaultButtonStyle, lightShadow,
    secondaryButtonProps,
    secondaryButtonStyle
} from "../../../styles/defaultStyles";
import {
    clearIsStack,
    clearMafiList,
    setIsNewMafi,
    setCurrentMafi, resetState
} from "../../../store/slices/union";
import {SnackbarUtil, MafiStackOrderComponent} from "@blg/blg-core/lib/esm/components";
import UnionDeleteStackDialogComponent from "../../../components/dialog/unionDeleteStackDialog.component";
import {Emitter, MafiInformation, RoTrailMafiService} from "@blg/blg-core";
import RfidTagSelectionDialogComponent, {
    RFIDScanResult
} from "../../../components/dialog/rfidTagSelectionDialogComponent";
import {asciiToHex, writeRFID} from "../../../misc/rfidHelper";
import isKeyboardOpen from "../../../misc/isKeyboardOpen";
import ReactRouterPrompt from "react-router-prompt";

const UnionView: React.FC = () => {
    const [mafiNr, setMafiNr] = useState<string>('');
    const isOpen = isKeyboardOpen(200);
    const [mafiWriteTag, setMafiWriteTag] = useState<RFIDScanResult | null>(null);
    const [showStackCloseDialog, setShowStackCloseDialog] = useState<boolean>(false);
    const [triggerValidation, setTriggerValidation] = useState(false);
    const [stackIsMinimized, setStackIsMinimized] = useState(false);

    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    const isStack = useAppSelector(state => state.union).isStack;
    const stackMafis = useAppSelector(state => state.union).mafis;

    const [tagSelection, setTagSelection] = useState<{ show: boolean, tags: RFIDScanResult[] }>({
        show: false,
        tags: []
    });

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveTutorial({tutorial: "UNION", show: true}));
        store.dispatch(setActiveView({text: t('TASK_SELECTION.UNION'), params: null}));

        if (isScanner()) {
            Emitter.on("TAGS", foundTags);
        }
        return () => {
            store.dispatch(setShowBackButton(true));
            if (isScanner()) {
                Emitter.removeListener("TAGS");
            }
        }

    }, []);

    useEffect(() => {
        if (mafiWriteTag) {
            onWriteClicked();
        }
    }, [mafiWriteTag]);
    /**
     * checks if the application is running on scanner or in browser
     */
    const isScanner = () => {
        return window.cordova !== undefined;
    }

    const foundTags = (tags: Array<RFIDScanResult>) => {
        if ((!tags || tags.length === 0) && !tagSelection.show) {
            SnackbarUtil.warning(t("UNION.NO_TAGS_FOUND"));
            return;
        }

        // Hide all messages
        SnackbarUtil.closeAll();
        if (tags.length === 1) {
            // If only one tag is found it can be automatically be selected
            setMafiWriteTag(tags[0]);
            return;
        }

        setTagSelection({show: true, tags: tags});
    };

    /**
     * is called when the write button is pressed
     */
    const onWriteClicked = async () => {
        setTriggerValidation(true);
        if (mafiNr.length < 6) {
            SnackbarUtil.info(t("ERROR.MIN_LENGTH", {min: 6}));
            return;
        }

        try {
            store.dispatch(setShowLoading(true));
            const useMinimalData = await loadMafiData();

            if (useMinimalData === null) {
                return;
            }

            if (window.cordova) {
                const isOldTag = mafiWriteTag !== null && parseInt(mafiWriteTag.pcValue) < 3000;
                writeRFID(mafiWriteTag!.id, asciiToHex(mafiNr), isOldTag, () => {
                    store.dispatch(setShowLoading(false));
                    SnackbarUtil.success(t("UNION.WRITE_TAG_SUCCESS"));
                    const pathToUse = useMinimalData ? ROUTES.UNION_ENTER_DATA : ROUTES.UNION_SAVE;
                    navigate(pathToUse);
                }, (error) => {
                    store.dispatch(setShowLoading(false));
                    SnackbarUtil.error("Fehler beim Schreiben des Tags");
                    console.log('write rfid error', error);
                });
            } else {
                const pathToUse = useMinimalData ? ROUTES.UNION_ENTER_DATA : ROUTES.UNION_SAVE;
                navigate(pathToUse);
            }
        } catch (e) {
            SnackbarUtil.error(e.message);
        } finally {
            store.dispatch(setShowLoading(false));
        }
    }

    const loadMafiData = async (): Promise<boolean | null> => {
        let setMinimalData = null;
        store.dispatch(setShowLoading(true));
        try {
            // searches for the mafi based on the number and gets the details of the mafi based on that
            const response = await RoTrailMafiService.instance.getByMafiNo(mafiNr);
            const data = MafiInformation.parseFromObject(response.data);

            store.dispatch(setCurrentMafi(data));
            store.dispatch(setIsNewMafi(false));
            setMinimalData = data.minimalData;
        } catch (e) {
            SnackbarUtil.error(t("UNION.MAFI_NOT_FOUND"));
        } finally {
            // removes the loading state
            store.dispatch(setShowLoading(false));
        }

        return setMinimalData;
    }

    const finishStackClicked = async () => {
        const success = await saveMafiStack(stackMafis);
        if (success) {
            // updating / creating was successful, therefore reset the union state
            store.dispatch(resetState());
        }
    }

    const onStackClose = () => {
        setShowStackCloseDialog(true);
    }

    const closeStackDialog = () => {
        setShowStackCloseDialog(false);
    }

    const onStackCloseConfirm = () => {
        setShowStackCloseDialog(false);
        store.dispatch(clearIsStack());
        store.dispatch(clearMafiList());
    }

    const onCancelPressed = () => {
        navigate(ROUTES.HOME);
    }

    const selectedMafi = (tag: RFIDScanResult) => {
        setMafiWriteTag(tag);
        setTagSelection({show: false, tags: []});
    }

    return (
        <Box sx={{
            height: "100%", display: "flex", alignItems: "center",
            flexDirection: "column", backgroundColor: theme.palette.surface.main
        }}>
            <Box sx={{display: "flex", flexDirection: "column", p: 1.5, width: "100%"}}>
                <ScanMafiNumberComponent
                    validationState={triggerValidation}
                    mafiNr={mafiNr}
                    writeMode
                    selectTagEvent={(i) => {
                        setMafiNr(i.parsedId);
                    }}
                />
                <FormControl variant={"filled"} sx={{width: '100%', mt: 2}}>
                    <TextField
                        sx={{...lightShadow}}
                        disabled
                        value={mafiWriteTag?.parsedId ?? ""}
                        label={t('SCAN_MAFI.SELECT_WRITE_TAG')}
                    />
                </FormControl>
                <Button
                    {...defaultButtonProps}
                    sx={{...defaultButtonStyle, mt: 4}}
                    onClick={onWriteClicked}
                    disabled={window.cordova && (mafiNr.length <= 0 || !mafiWriteTag)}>
                    {t('GENERAL.WRITE')}
                </Button>
                {stackMafis && stackMafis.length > 0 &&
                    <Button
                        {...secondaryButtonProps}
                        sx={{...secondaryButtonStyle, mt: 2}}
                        onClick={finishStackClicked}>
                        {t('UNION.FINISH_STACK')}
                    </Button>
                }
                <Button
                    {...secondaryButtonProps}
                    sx={{...secondaryButtonStyle, mt: 2, mb: 7.5}}
                    onClick={onCancelPressed}>
                    {t('GENERAL.CANCEL')}
                </Button>
            </Box>
            {isStack &&
                <Box sx={{
                    overflow:  `${stackIsMinimized ? "hidden" : "auto"}`,
                    maxHeight: `${stackIsMinimized ? "50px" : "285px"}`,
                    width: "100%",
                    mt: 3,
                    position: 'absolute',
                    bottom: 0,
                    display: `${isOpen ? 'none' : 'block'}`
                }}>
                    <MafiStackOrderComponent isMinimized={stackIsMinimized}
                                             items={stackMafis}
                                             slim
                                             onCloseCallback={onStackClose}
                                             onMinimizeCallback={() => {
                                                 setStackIsMinimized((prevState) => !prevState);
                                             }}/>
                </Box>
            }
            <UnionDeleteStackDialogComponent
                showStackCloseDialog={showStackCloseDialog}
                onCancel={closeStackDialog}
                onConfirm={onStackCloseConfirm}
            />
            <RfidTagSelectionDialogComponent
                showDialog={tagSelection.show}
                selectEvent={(info) => selectedMafi(info)}
                closeEvent={() => {
                    setTagSelection({show: false, tags: []});
                }}
                tags={tagSelection.tags}/>
            <ReactRouterPrompt
                when={({nextLocation}) => {
                    return stackMafis && stackMafis.length > 0
                        && nextLocation.pathname !== ROUTES.UNION &&
                        (nextLocation.pathname !== ROUTES.UNION_ENTER_DATA && nextLocation.pathname !== ROUTES.UNION_SAVE);
                }}
            >
                {({isActive, onConfirm, onCancel}) =>
                    isActive && (
                        <Dialog
                            fullWidth
                            open={isActive}
                        >
                            <DialogTitle>
                                Stack-Informationen nicht gespeichert
                            </DialogTitle>
                            <DialogContent>
                                <Box sx={{display: "flex", flexDirection: "column"}}>
                                    <Typography variant={"body2"}>
                                        Beim Verlassen der Ansicht gehen die nicht gespeicherten Stack-Informationen
                                        verloren
                                    </Typography>
                                </Box>
                            </DialogContent>
                            <DialogActions sx={{p: 3}}>
                                <Button
                                    sx={{color: `${theme.palette.primary.main}`}}
                                    onClick={onConfirm}>
                                    {t("GENERAL.CONFIRM")}
                                </Button>
                                <Button
                                    sx={{color: `${theme.palette.primary.main}`}}
                                    onClick={onCancel}>
                                    {t("GENERAL.CANCEL")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </ReactRouterPrompt>
        </Box>
    );
};

export default UnionView;
