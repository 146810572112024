import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import enTranslation from "./locales/en/en.json";
import deTranslation from "./locales/de/de.json";

import englishCoreTranslation from '@blg/blg-core/lib/esm/locales/en/en.json';
import germanCoreTranslation from '@blg/blg-core/lib/esm/locales/de/de.json';

export const defaultNS = "translation";

export const resources = {
    de: {translation: deTranslation, core: germanCoreTranslation},
    en: {translation: enTranslation, core: englishCoreTranslation},
} as const;

void i18n
    .use(initReactI18next)
    .init({
        detection: {
            order: ["path"],
            lookupFromPathIndex: 0,
        },
        fallbackLng: "de",
        debug: true,
        resources,
        defaultNS,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
