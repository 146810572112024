import {MafiInformation} from "@blg/blg-core";
import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface UnionState {
    mafis: MafiInformation[],
    currentMafi: MafiInformation | null,
    isStack: boolean,
    isNewMafi: boolean | null
}

const initialState: UnionState = {
    mafis: [],
    currentMafi: null,
    isNewMafi: false,
    isStack: false,
};

const unionState = createSlice({
    name: "union",
    initialState,
    reducers: {
        setCurrentMafi(state: UnionState, action: PayloadAction<MafiInformation>) {
            state.currentMafi = action.payload;
        },
        setMafis(state: UnionState, action: PayloadAction<MafiInformation[]>) {
            state.mafis = action.payload;
        },
        setMafiInList(state: UnionState, action: PayloadAction<MafiInformation>) {
            console.log('action.payload', action.payload);
            const index = state.mafis.findIndex((m) => m.mafiNo === action.payload?.mafiNo);
            if (index >= 0) {
                state.mafis.splice(index, 1, action.payload);
            } else {
                state.mafis.splice(0, 0, action.payload);
            }
            console.log('state.mafis', state.mafis);
        },
        removeMafiFromList(state: UnionState, action: PayloadAction<MafiInformation>) {
            const index = state.mafis.findIndex((m) => m.mafiNo === action.payload.mafiNo);
            if (index < 0) return;
            state.mafis.splice(index, 1);
        },
        setIsNewMafi(state: UnionState, action: PayloadAction<boolean | null>) {
            state.isNewMafi = action.payload;
        },
        setIsStack(state: UnionState, action: PayloadAction<boolean>) {
            state.isStack = action.payload;
        },
        clearCurrentMafi(state: UnionState) {
            state.currentMafi = null;
        },
        clearMafiList(state: UnionState) {
            state.mafis = [];
        },
        clearIsStack(state: UnionState) {
            state.isStack = initialState.isStack;
        },
        resetState(state: UnionState) {
            state.mafis = initialState.mafis;
            state.currentMafi = initialState.currentMafi;
            state.isNewMafi = initialState.isNewMafi;
            state.isStack = initialState.isStack;
        }
    },
});

export const {
    setMafis,
    setMafiInList,
    setCurrentMafi,
    setIsNewMafi,
    setIsStack,
    removeMafiFromList,
    clearCurrentMafi,
    clearMafiList,
    clearIsStack,
    resetState,
} = unionState.actions;
export default unionState.reducer;
