import {useTheme} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';

import {useAppSelector} from "../../store/hooks";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/routes";
import {useLocation} from 'react-router-dom';
import {store} from "../../store/store";
import { BuildInfoDialogComponent } from "@blg/blg-core";
import TutorialDialogComponent from "../dialog/tutorialDialog.component";

const NavigationComponent = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const activeView = useAppSelector(state => state.global).activeView;
    const activeTutorial = useAppSelector(state => state.global).activeTutorial;
    const globalBackButton = useAppSelector(state => state.global).showBackButton;

    const mdeLatitude = useAppSelector(state => state.custom).latitude;
    const mdeLongitude = useAppSelector(state => state.custom).longitude;

    const username = useAppSelector(state => state.auth).username;
    const [showTutorial, setShowTutorial] = useState(false);
    const [showBackButton, setShowBackButton] = useState(false);
    const [showBuildInfo, setShowBuildInfo] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setShowBackButton(location.pathname !== ROUTES.HOME && location.pathname !== ROUTES.TASK_SELECTION && globalBackButton);
    }, [location, activeTutorial.show, globalBackButton]);

    return (
        <Box>
            <AppBar position="static">
                <Toolbar sx={{display: "flex", justifyContent: "space-between", px: 1}}>
                    <Box sx={{display: "flex", flex: 1}}>
                        <IconButton
                            sx={{color: theme.palette.white.main}}
                            size="large"
                            edge="start"
                            onClick={() => {
                                navigate(ROUTES.HOME, {replace: true})
                            }}
                        >
                            <HomeIcon/>
                        </IconButton>
                        { showBackButton &&
                            <IconButton
                                sx={{color: theme.palette.white.main}}
                                size="large"
                                edge="start"
                                onClick={() => {
                                    navigate((-1));
                                }}
                            >
                                <ArrowBackIcon/>
                            </IconButton>
                        }
                    </Box>
                    {activeView.text !== "" &&
                        <Typography variant="h6" sx={{flexGrow: 1, color: theme.palette.white.main, textAlign: "center"}}>
                            { /* @ts-ignore  */}
                            {t(activeView.text as any, activeView.params)}
                        </Typography>
                    }
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", flex: 1}}>
                        { activeTutorial.show &&
                            <Box onClick={() => setShowTutorial(true)}
                                 sx={{
                                     display: "flex", flexDirection: "column", alignItems: "center",
                                     mr: 1, cursor: "pointer"
                                 }}>
                                <HelpIcon sx={{color: theme.palette.white.main}}/>
                                <Typography variant={"caption"} sx={{
                                    color: theme.palette.white.main,
                                    mt: 0.5,
                                    textAlign: "center",
                                    lineHeight: "1.3"
                                }}>
                                    Hilfe
                                </Typography>
                            </Box>
                        }
                        <Box onClick={() => setShowBuildInfo(true)}
                             sx={{
                                 display: "flex", flexDirection: "column", alignItems: "center",
                                 mr: 1, cursor: "pointer"
                             }}>
                            <InfoIcon sx={{color: theme.palette.white.main}}/>
                            <Typography variant={"caption"} sx={{color: theme.palette.white.main, mt: 0.5, textAlign: "center", lineHeight: "1.3"}}>
                                {process.env.REACT_APP_VERSION}
                            </Typography>
                        </Box>
                        <IconButton
                            onClick={() => {
                                store.dispatch({type: "CLEAR_STORE"});
                                navigate(ROUTES.LOGIN, {replace: true});
                            }}
                            color="inherit">
                            <LogoutIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <BuildInfoDialogComponent showDialog={showBuildInfo} username={username!}
                                      latitude={mdeLatitude}
                                      longitude={mdeLongitude}
                                      closeEvent={() => setShowBuildInfo(false)}/>
            <TutorialDialogComponent showDialog={showTutorial} closeEvent={() => setShowTutorial(false)}/>
        </Box>
    )
}

export default NavigationComponent;
