import React from 'react';
import {ThemeProvider} from "@mui/material/styles";
import {Provider} from "react-redux";
import {persistor, store} from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import {createHashRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {LicenseInfo} from '@mui/x-license-pro';

import {
    SnackbarUtilsConfigurator
} from "@blg/blg-core/lib/esm/components";
import {theme} from "./styles/theme";

import HomeView from "./views/home.view";
import {ROUTES} from "./routes/routes";
import LoginView from "./views/login.view";
import DefaultView from "./views/default.view";
import UnionOrderStackView from "./views/tasks/orderStack/orderStack.view";
import StackView from "./views/tasks/stack/stack.view";
import StackDetailView from "./views/tasks/stack/stackDetail.view";
import StackOptionsView from "./views/tasks/stackOptions.view";
import UnstackView from "./views/tasks/unstack/unstack.view";
import UnStackDetailView from "./views/tasks/unstack/unStackDetail.view";
import TaskSelectionView from "./views/taskSelection.view";
import UnionView from "./views/tasks/union/union.view";
import InventoryView from "./views/tasks/inventory/inventory.view";
import SetPlaceView from "./views/tasks/setPlace/setPlace.view";
import UnionEnterDataView from "./views/tasks/union/unionEnterData.view";
import MafiInfoView from "./views/tasks/mafiInfo/mafiInfo.view";
import InventoryScanView from "./views/tasks/inventory/inventory_scan.view";
import MafiInfoDetailsView from "./views/tasks/mafiInfo/mafiInfoDetails.view";
import SetPlaceDetailsView from "./views/tasks/setPlace/setPlaceDetails.view";
import UnionSaveView from "./views/tasks/union/unionSave.view";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';

declare global {
    interface Window {
        RFIDConnector: any;
        cordova: any;
    }
    interface Navigator {
        app: any;
        device: any;
    }
}
function App() {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY!);

    const router = createHashRouter(
        createRoutesFromElements(
            <Route path={ROUTES.ROOT} element={<DefaultView/>}>
                <Route path={ROUTES.LOGIN} element={<LoginView/>}/>
                <Route path={ROUTES.HOME} element={<HomeView/>}>
                    {/* task selection view  */}
                    <Route index element={<TaskSelectionView/>}/>
                    <Route path={ROUTES.TASK_SELECTION} element={<TaskSelectionView/>}/>

                    {/* union views */}
                    <Route path={ROUTES.UNION} element={<UnionView/>}/>
                    <Route path={ROUTES.UNION_ENTER_DATA} element={<UnionEnterDataView/>}/>
                    <Route path={ROUTES.UNION_SAVE} element={<UnionSaveView />} />
                    <Route path={ROUTES.UNION_ORDER_STACK} element={<UnionOrderStackView />} />

                    {/* inventory views */}
                    <Route path={ROUTES.INVENTORY} element={<InventoryView/>}/>
                    <Route path={ROUTES.INVENTORY_SCAN} element={<InventoryScanView/>}/>

                    {/* set place views */}
                    <Route path={ROUTES.SET_PLACE} element={<SetPlaceView/>}/>
                    <Route path={ROUTES.SET_PLACE_DETAILS} element={<SetPlaceDetailsView />} />

                    {/* stack views */}
                    <Route path={ROUTES.STACK_TASK} element={<StackOptionsView/>}/>
                    <Route path={ROUTES.STACK} element={<StackView />} />
                    <Route path={ROUTES.STACK_DETAILS} element={<StackDetailView />} />
                    <Route path={ROUTES.UNSTACK} element={<UnstackView />} />
                    <Route path={ROUTES.UNSTACK_DETAILS} element={<UnStackDetailView />} />

                    {/* mafi info views */}
                    <Route path={ROUTES.MAFI_INFO} element={<MafiInfoView/>}/>
                    <Route path={ROUTES.MAFI_INFO_DETAILS} element={<MafiInfoDetailsView/>}/>
                </Route>
            </Route>
        )
    );

    return (
        <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}
                              autoHideDuration={2500}
                              hideIconVariant={false}
                              anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
                <SnackbarUtilsConfigurator/>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <RouterProvider router={router} />
                    </PersistGate>
                </Provider>
            </SnackbarProvider>
        </ThemeProvider>
        </I18nextProvider>
    );
}

export default App;
