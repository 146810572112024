import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export type Tutorial = "FUNCTION" | "LOCATION" | "UNION" | "UNION_MINIMAL" | "UNION_SAVE" | "LOCATION_DETAILS"

export interface GlobalState {
    showLoading: boolean,
    loadingText: string | undefined,
    showBackButton: boolean,
    activeView: {
        text: string, params: any
    },
    isOnline: boolean,
    scannerConnection: boolean,
    activeTutorial: { tutorial: Tutorial, show: boolean },
}

const initialState: GlobalState = {
    showLoading: false,
    loadingText: undefined,
    showBackButton: true,
    activeView: {text: "", params: null},
    isOnline: true,
    scannerConnection: false,
    activeTutorial: { tutorial: "FUNCTION", show: true }
};

const globalState = createSlice({
    name: "global",
    initialState,
    reducers: {
        setShowLoading(state: GlobalState, action: PayloadAction<boolean>) {
            state.showLoading = action.payload;
        },
        setShowLoadingText(state: GlobalState, action: PayloadAction<string | undefined>) {
            state.loadingText = action.payload;
        },
        setShowBackButton(state: GlobalState, action: PayloadAction<boolean>) {
            state.showBackButton = action.payload;
        },
        setActiveView(state: GlobalState, action: PayloadAction<{
            text: string, params: any
        }>) {
            state.activeView = action.payload;
        },
        setIsOnline(state: GlobalState, action: PayloadAction<boolean>) {
            state.isOnline = action.payload;
        },
        setScannerConnection(state: GlobalState, action: PayloadAction<boolean>) {
            state.scannerConnection = action.payload;
        },
        setActiveTutorial(state: GlobalState, action: PayloadAction<{ tutorial: Tutorial, show: boolean }>) {
            state.activeTutorial = action.payload;
        },
    },
});

export const {setShowLoading, setActiveView, setIsOnline, setScannerConnection, setActiveTutorial, setShowBackButton,
setShowLoadingText} = globalState.actions;
export default globalState.reducer;
