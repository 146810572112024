import React, {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {store} from "../../../store/store";
import {setActiveTutorial, setActiveView} from "../../../store/slices/global";
import ScanMafiNumberComponent from "../../../components/mafi/scanMafiNumber.component";
import {generatePath} from "react-router";
import {ROUTES} from "../../../routes/routes";
import {useNavigate} from "react-router-dom";
import {
    defaultButtonProps,
    defaultButtonStyle,
    secondaryButtonProps,
    secondaryButtonStyle
} from "../../../styles/defaultStyles";
import {RFIDScanResult} from "../../../components/dialog/rfidTagSelectionDialogComponent";

/**
 * set place view,
 * allows the user to set select a mafi in order to set the place of them
 * @constructor
 */
const SetPlaceView: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const [mafiNr, setMafiNr] = useState<string>('');

    function openMafiDetailsView(mafiNo: string) {
        const routingPath = generatePath(ROUTES.SET_PLACE_DETAILS, {mafiNr: mafiNo});
        navigate(routingPath);
    }

    function onCancelPressed() {
        navigate(ROUTES.HOME);
    }

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveTutorial({ tutorial: "LOCATION", show: true }));
        store.dispatch(setActiveView({text: t('TASK_SELECTION.SET_PLACE'), params: null}));
    }, []);

    return (
        <Box sx={{
            display: "flex", flexDirection: "column", minHeight: '100%',
            backgroundColor: theme.palette.surface.main
        }}>
            <Box sx={{width: '100%', p: 1}}>
                <ScanMafiNumberComponent
                    mafiNr={mafiNr}
                    selectTagEvent={async (tag: RFIDScanResult, isInput) => {
                        setMafiNr(tag.parsedId)

                        if (!isInput) {
                            openMafiDetailsView(tag.parsedId);
                        }
                    }}/>
                <Button
                    {...defaultButtonProps}
                    sx={{...defaultButtonStyle, marginTop: '20px'}}
                    disabled={!mafiNr || mafiNr.length <= 0}
                    onClick={() => {
                        openMafiDetailsView(mafiNr);
                    }}
                >{t('SET_PLACE.SET_PLACE')}</Button>
                <Button
                    {...secondaryButtonProps}
                    sx={{...secondaryButtonStyle, mt: 2, mb: 1}}
                    onClick={onCancelPressed}>
                    {t('GENERAL.CANCEL')}
                </Button>
            </Box>

            {/* hint text that is shown when the user hasn't scanned the mafi nr */}
            <Typography sx={{fontSize: '14px', p: 2, fontWeight: 500, lineHeight: '14px'}}>
                {t('SET_PLACE.DESCRIPTION')}
            </Typography>
        </Box>
    );
};

export default SetPlaceView;
