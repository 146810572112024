import React from "react";
import {useTranslation} from "react-i18next";
import {ConfirmDialog} from "@blg/blg-core";

/**
 * props used for the dialog
 */
export interface UnionDeleteStackDialogComponentProps {
	showStackCloseDialog: boolean,
	onCancel?: () => void,
	onConfirm?: () => void,
}

/**
 * dialog to confirm the remove of stack information from the union view
 * @param props
 * @constructor
 */
const UnionDeleteStackDialogComponent = (props: UnionDeleteStackDialogComponentProps) => {
	const {t} = useTranslation();

	/**
	 * is called when the user taps on the cancel button
	 */
	const onCancel = () => {
		props.onCancel?.call(null);
	}

	/**
	 * is called when the user taps on the confirm button
	 */
	const onConfirm = () => {
		props.onConfirm?.call(null);
	}

	return <ConfirmDialog
		showDialog={props.showStackCloseDialog}
		text={t('UNION.DELETE_STACK.DESCRIPTION')}
		title={t('UNION.DELETE_STACK.TITLE')}
		confirmText={t('GENERAL.CONFIRM')}
		declineText={t('GENERAL.CANCEL')}
		confirmEvent={onConfirm}
		declineEvent={onCancel}
	/>
}

export default UnionDeleteStackDialogComponent;