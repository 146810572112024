import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import WetDialogComponent from "../../../components/dialog/wetDialog.component";
import MafiInformationComponent from "../../../components/mafi/mafiInformation.component";
import LoadingStatusComponent from "../../../components/mafi/loadingStatus.component";
import {ROUTES} from "../../../routes/routes";
import {setLastSavedLocation} from "../../../store/slices/custom";
import {
    defaultButtonProps,
    defaultButtonStyle,
    secondaryButtonProps,
    secondaryButtonStyle
} from "../../../styles/defaultStyles";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {store} from "../../../store/store";
import {setActiveTutorial, setActiveView, setShowBackButton} from "../../../store/slices/global";
import {useAppSelector} from "../../../store/hooks";
import {clearCurrentMafi, resetState, setCurrentMafi, setIsStack, setMafiInList,} from "../../../store/slices/union";
import { MafiInformation, LiveMafiLocationComponent, MafiNotation, MafiLocationCoordinates } from "@blg/blg-core";
import {saveMafi, saveMafiStack} from "misc/mafiHelper";

const UnionSaveView = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const mdeLatitude = useAppSelector(state => state.custom).latitude;
    const mdeLongitude = useAppSelector(state => state.custom).longitude;

    /**
     * the last saved location
     */
    const lastSavedLocation = useAppSelector(state => state.custom).lastSavedLocation;

    /**
     * the length of the mafi stack list (used ot identify if the user can add another
     * mafi to the stack list
     */
    const mafisLength = useAppSelector(state => state.union).mafis.length;

    /**
     * the stored mafi information of the current mafi
     */
    const information = useAppSelector(state => state.union).currentMafi;

    /**
     * flag that indicates whether the mafi is new or not
     * (to use either the create or update endpoint)
     */
    const isNewMafi = useAppSelector(state => state.union).isNewMafi;
    const isStack = useAppSelector(state => state.union).isStack;
    const mafis = useAppSelector(state => state.union).mafis;

    const [notation, setNotation] = useState<MafiNotation | null>(null);
    const [location, setLocation] = useState<MafiLocationCoordinates | null>(null);
    const [locationValid, setLocationValid] = useState(false);

    const [showWetDialog, setShowWetDialog] = useState(false);

    /**
     * sets the view header
     */
    useEffect(() => {
        store.dispatch(setActiveTutorial({ tutorial: "UNION_SAVE", show: true }));
        store.dispatch(setActiveView({text: t('TASK_SELECTION.UNION'), params: null}));

        if (mafis.length > 0) {
            const parentMafi = mafis[0];
            const long = Number(parentMafi.mafiLocation.geoLocLongitude);
            const lat = Number(parentMafi.mafiLocation.geoLocLatitude);
            store.dispatch(setCurrentMafi(MafiInformation.parseFromObject({
                ...information,
                mafiLocation: parentMafi.mafiLocation,
            })));
            setNotation(parentMafi.mafiNotation!);
            setLocation({ long, lat });
            setLocationValid(true);
        }
    }, []);

    /**
     * is called when the user clicks on the save button,
     * tries to create / update the scanned mafi (based on if the mafi is new or
     * not)
     */
    const onSavePressed = async (confirmed?: boolean) => {
        // Show the confirm dialog for wet sensitive data
        if (!confirmed && information!._hasWetSensitiveData && !notation!.wetSensitive) {
            setShowWetDialog(true);
            return;
        }

        const {result, apiMafi} = await saveMafi(information!, notation!.notation, location!, isNewMafi!, isStack);

        if (!result) {
            return;
        }

        if (isStack) {
            store.dispatch(setMafiInList(apiMafi!));
            const stackResult = await saveMafiStack([apiMafi!, ...mafis]);
            if (stackResult) {
                resetAndOpenUnionView();
            }
        } else {
            resetAndOpenUnionView();
        }
    }

    const resetAndOpenUnionView = () => {
        store.dispatch(setShowBackButton(false));
        // updating / creating was successful, therefore reset the union state
        // and navigate back to the union view
        store.dispatch(resetState());
        navigate(ROUTES.UNION);
    }

    const onAnotherMafiPressed = async () => {
        if (!information) return;

        // Create or update the current mafi first
        const {result, apiMafi} = await saveMafi(information!, notation!.notation, location!, isNewMafi!, isStack);

        if (!result) {
            return;
        }

        apiMafi!.mafiNotation = notation!;

        store.dispatch(setMafiInList(apiMafi!));
        store.dispatch(setIsStack(true));

        store.dispatch(clearCurrentMafi());

        return apiMafi;
    }

    const onLoadingStatusChanged = (status: 'VOLL' | 'LEER') => {
        store.dispatch(setCurrentMafi(MafiInformation.parseFromObject({
            ...information,
            loadStatus: status,
        })));
    }

    const onNotationChanged = (isValid: boolean, value?: MafiNotation) => {
        if (value) {
            setNotation(value);
            store.dispatch(setLastSavedLocation(value.notation));
        }
        setLocationValid(isValid);
    }

    const onLocationChanged = (value: MafiLocationCoordinates) => {
        setLocation(value);
    }

    /**
     * check if the max size of stack has been reached
     */
    const hasReachedMaxStackLength = () => {
        return false;
        // return mafisLength + 1 >= parseInt(process.env.REACT_APP_MAX_STACK_AMOUNT!);
    }

    return <Box sx={{ height: "100%",
        minHeight: "100%", display: "flex", flexDirection: "column", p: 1.5, overflowY: "auto"
    }}>
        {/* for testing and developing (on hot restart) it can happen that the information are empty */}
        {information && <Box>
            <MafiInformationComponent information={information} showDetailed={false}/>

            <Box sx={{backgroundColor: theme.palette.white.main, mt: 2.5, p: 1.5, borderRadius: "6px"}}>
                { mafis.length > 0 &&
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{fontSize: '16px', fontWeight: '600', mb: 1.5 }}>{ t("SET_PLACE.LOCATION_SYSTEM")} </Typography>
                        <Typography variant={"h6"}>{mafis[0]!.mafiLocation.location}</Typography>
                    </Box>
                }
                { !mafis || mafis.length === 0 &&
                    <LiveMafiLocationComponent
                        dangerousGood={information._hasDangerousData}
                        latitude={mdeLatitude}
                        longitude={mdeLongitude}
                        lastSavedLocation={lastSavedLocation}
                        title={t('GENERAL.PLACE')}
                        onNotationChangedCallback={onNotationChanged}
                        onLocationChangedCallback={onLocationChanged}
                    />
                }

            </Box>

            <Box sx={{mt: 2.5}}>
                <LoadingStatusComponent
                    disabled={mafis.length > 0}
                    information={information}
                    isParentUpdating={true}
                    onStatusChanged={onLoadingStatusChanged}
                />
            </Box>

            <Box>
                <Button
                    {...defaultButtonProps}
                    disabled={!information || !location || !locationValid}
                    sx={{...defaultButtonStyle, mt: 2.5}}
                    onClick={() => onSavePressed(false)}
                >{t("GENERAL.SAVE_AND_BACK")}</Button>
                { mafisLength > 0 &&
                    <Button
                        {...defaultButtonProps}
                        disabled={!information || !location || !locationValid}
                        sx={{...defaultButtonStyle, mt: 2.5}}
                        onClick={async () => {
                            const apiMafi = await onAnotherMafiPressed();
                            navigate(ROUTES.UNION_ORDER_STACK, { state: { apiMafi } });
                        }}
                    >{t("UNION.CHANGE_ORDER")}</Button>
                }
                {!hasReachedMaxStackLength() && <Button
                    {...secondaryButtonProps}
                    disabled={!information || !location || !locationValid}
                    sx={{...secondaryButtonStyle, mt: 2.5, mb: 3}}
                    onClick={async () => {
                        await onAnotherMafiPressed();
                        store.dispatch(setShowBackButton(false));
                        navigate(ROUTES.UNION, { replace: true });
                    }}>
                    {t('UNION.ADD_ANOTHER_MAFI')}
                </Button>}
            </Box>
        </Box>}
        <WetDialogComponent showDialog={showWetDialog} closeDialog={async (confirmed: boolean) => {
            setShowWetDialog(false);
            if (confirmed) {
                await onSavePressed(confirmed);
            }

        }}></WetDialogComponent>
    </Box>
}

export default UnionSaveView;

