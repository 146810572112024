import {Category, Parseable, TableInformation} from "@blg/blg-core";
import {BlgTableBaseModel} from "@blg/blg-core/lib/esm/models/blgTable.base.model";
import {GridCellParams} from "@mui/x-data-grid-premium";
import {Radio, Typography} from "@mui/material";
import React from "react";
import {LashingCategory} from "../enum/lashingCategory.enum";

export default class MafiCategory extends Parseable implements Category, BlgTableBaseModel {
    public id!: number;
    public code!: string;
    public text!: string;

    public length!: number;
    public weight!: number;
    public lashingCategory!: LashingCategory;


    public static parseFromObject(object: any): MafiCategory {
        const order = new MafiCategory();
        Object.assign(order, object);
        return order;
    }

    public filter(): boolean {
        return false;
    }

    getTableInformation(): TableInformation[] {
        return [{
            field: 'radio-button',
            headerName: '',
            headerClassName: 'cargotable-header',
            disableColumnMenu: true,
            width: 66,
            sortable: false,
            renderCell(rowData: GridCellParams): any {
                return <Radio
                    value={rowData.row.id}
                />;
            }
        },{
            field: 'text',
            headerName: 'Kategorie',
            headerClassName: 'cargotable-header',
            disableColumnMenu: true,
            width: 210,
            sortable: false
        },{
            field: 'weight',
            headerName: 'Gewicht',
            width: 210,
            headerClassName: 'cargotable-header',
            disableColumnMenu: true,
            sortable: false
        },{
            field: 'length',
            width: 210,
            headerName: 'Länge',
            headerClassName: 'cargotable-header',
            disableColumnMenu: true,
            sortable: false
        },{
            field: 'lashingCategory',
            width: 200,
            headerName: 'MAFI_SEARCH.LASHING_METHOD',
            headerClassName: 'cargotable-header',
            disableColumnMenu: true,
            sortable: false,
            renderCell(rowData: GridCellParams<any, string>): any {
                return <Typography>{rowData.value}</Typography>
            }
        }
        ];
    }
}
