import {Box, Button} from "@mui/material";
import ScanMafiNumberComponent from "../../../components/mafi/scanMafiNumber.component";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {store} from "../../../store/store";
import {setActiveView, setShowLoading} from "../../../store/slices/global";
import {defaultButtonProps, defaultButtonStyle} from "../../../styles/defaultStyles";
import {generatePath} from "react-router";
import {ROUTES} from "../../../routes/routes";
import {SnackbarUtil} from "@blg/blg-core/lib/esm/components";
import {MafiInformation, RoTrailMafiService} from "@blg/blg-core";

const UnstackView = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveView({text: t('STACK.OPTIONS.UNSTACK'), params: null}));
        // store.dispatch(setActiveTutorial({ tutorial: "UNSTACK", show: true }));
    }, []);

    /**
     * the current mafiNr
     */
    const [mafiNr, setMafiNr] = useState<string>('');

    /**
     * is called when the user presses on the search button, tries to search for a mafi in the
     * system with the corresponding number
     */
    const onSearchPressed = async (mafiSearch: string) => {
        try {
            store.dispatch(setShowLoading(true));
            const response = await RoTrailMafiService.instance.getByMafiNo(mafiSearch);
            if (response) {
                const mafi = MafiInformation.parseFromObject(response.data);
                setMafiNr(mafi.mafiNo);

                if (!mafi.stackNo) {
                    SnackbarUtil.error(t('UNSTACK.NO_STACK_FOR_MAFI'));
                    return;
                }

                const path = generatePath(ROUTES.UNSTACK_DETAILS, {'mafiNo': mafi.mafiNo});
                navigate(path);
            }
        } catch (e) {
            SnackbarUtil.error(t('SCAN_MAFI.NO_MAFI'));
        } finally {
            store.dispatch(setShowLoading(false));
        }
    }

    return <Box sx={{
        height: "100%", display: "flex", alignItems: "center", flexDirection: "column"
    }}>
        <Box sx={{p: 1.5, display: 'flex', flexDirection: 'column', width: '100%'}}>
            <ScanMafiNumberComponent mafiNr={mafiNr} selectTagEvent={(tag, isInput) => {
                setMafiNr(tag.parsedId);
                if (!isInput) {
                    onSearchPressed(tag.parsedId);
                }
            }}/>
            <Button
                disabled={mafiNr.length <= 0}
                {...defaultButtonProps}
                sx={{...defaultButtonStyle, mt: 1.5}}
                onClick={() => onSearchPressed(mafiNr)}>
                {t('GENERAL.SEARCH_MAFI')}
            </Button>
        </Box>
    </Box>;
}

export default UnstackView;
