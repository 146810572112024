import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {defaultButtonProps, defaultButtonStyle} from "../../styles/defaultStyles";

export interface EmptyMafiPlaceholderProps {
	mafiNr: string,
}

const EmptyMafiPlaceholder = (props: EmptyMafiPlaceholderProps) => {
	const navigate = useNavigate();
	const {t} = useTranslation();

	function goBack() {
		navigate((-1));
	}

	return (
		<Box sx={{px: 4, textAlign: 'center', justifyContent: 'center', flexDirection: 'column', pt: 8, display: 'flex', alignItems: 'center', flex: 1}}>
			<Typography sx={{fontWeight: 'bold'}}>{t('MAFI_INFORMATION.EMPTY_TITLE')}</Typography>
			<Typography>{t('MAFI_INFORMATION.EMPTY', {'value': props.mafiNr})}</Typography>
			<Button
				onClick={goBack}
				{...defaultButtonProps}
				sx={{...defaultButtonStyle, mt: 4}}
			>{t('MAFI_INFORMATION.EMPTY_BUTTON')}</Button>
		</Box>
	);
};

export default EmptyMafiPlaceholder;
